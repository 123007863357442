<template>
    <div class=" w-100 d-flex flex-column flex-sm-row justify-sm-center  justify-md-space-between  pb-2 pt-2 pr-1" style="box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;">
        <div id="component-search-customer-seller">
            <v-form ref="form" class="d-flex align-items-center align-center">
                <a @click="goBackAction()" v-if="showGoback" class="pa-2">  <img src="img/arrow-u-left-top.svg"></a>
                <label class="ml-4 mr-2">Zona:</label>              
                <v-text-field  hide-details="auto" small dense solo required 
                    v-model="seller_code" 
                    class="text-ped pt-0  mb-0"
                    @keyup="customer_code = null"
                    :disabled="!!customer_code || loading" 
                    type="text"
                    :rules="[(v) => v === null || /^\d+$/.test(v) || 'Solo números enteros']"                                            
                >
                <template v-slot:append>
                        <v-btn v-if="seller_code" icon @click="clearInput" :disabled="loading">
                        <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>

                <label class="ml-4 mr-2">Cliente:</label>  
                <v-text-field  hide-details="auto" small dense solo 
                    v-model="customer_code"   
                    lass="text-ped pt-0  mb-0"   
                    :rules="[(v) => v === null || /^\d+$/.test(v) || 'Solo números enteros']"
                    :disabled="!!seller_code || loading">
                        <template v-slot:append>
                            <v-btn v-if="customer_code" icon @click="clearInput" :disabled="loading">
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                <div class="ml-4">
                    <v-btn :loading="loading" @click="sendCode()" class="btn-danger btn-custom rounded-pill" block>Consultar</v-btn>
                </div>
            </v-form>
        </div>
        <div class="d-flex justify-center align-center pt-3 pt-sm-0 ml-3 ml-sm-1">
                <slot name="right-actions" ></slot>  
        </div> 
    </div>
</template>

<script>
import axios from "@/plugins/axios";
import { mapGetters } from "vuex";
export default {
    props: {
        showGoback: {
            type: Boolean,
            default:true, 
        },
        customGobackAction:{
            type: Boolean,
            default:false, 
        },
    },
    data(){
        return{
            seller_code: null,
            customer_code: null,
            loading:false,
        }
    },  
    computed:{
        ...mapGetters({
          authenticated: "auth/authenticated",
          user: "auth/user",
        }),
    },  
    methods:{
        clearInput() {
            this.$refs.form.reset();
        },
        async sendCode(){            
            if (this.validate()) {
                try{
                    this.loading = true;
                    let P_USRNA = this.seller_code != null ? this.seller_code : this.customer_code
                    let P_IDKOU = this.seller_code != null ? 51 : 50
                    let response = await axios.get('/search-user',{
                        params : {
                            P_USRNA: P_USRNA,
                            P_VTWEG: this.user.VTWEG,
                            P_IDKOU: P_IDKOU
                        }
                    })
                    this.loading = false;
                    let data = response.data.data
                    if(data[0].FNMSG == '1'){
                        if(this.seller_code != null){ 
                            this.$setLocalStorageSeller(this.seller_code)   
                            // localStorage.setItem('currentSellerCode', JSON.stringify(this.seller_code));                     
                            this.$router.push({name: "SellerResults"})
                        }else{     
                            this.$setLocalStorageCustomer(this.customer_code)          
                            // localStorage.setItem('currentCustomerCode', JSON.stringify(this.customer_code));          
                            this.$router.push({name: "CustomerCoverDetails"})
                        }
                    }else{
                        const message = this.seller_code != null ? `No existe el código de gerente ${this.seller_code}` : `No existe el código de cliente ${this.customer_code}`
                        this.$toast.error(message)
                    }
                }catch(error){
                    this.loading = false;
                    this.$toast.error('Ocurrió un error al consultar el código. Por favor inténtelo de nuevo')
                }
            }
        },
        goBackAction(){
            this.customGobackAction ? this.$emit('goBackAction'): this.callback();            
        },
        validate(){
            const isFormValid = this.$refs.form.validate();
            return (this.seller_code && this.seller_code.trim() !== '' && isFormValid) || (this.customer_code && this.customer_code.trim() !== '' && isFormValid)
        }
    }
}
</script>

<style >
    #component-search-customer-seller .btn-custom{
      background: transparent linear-gradient(180deg, #A82222 0%, #FF0000 100%) 0% 0% no-repeat padding-box;
          opacity: 1;
          color: #FFF !important;
          text-transform: inherit !important;
          font-size: 12px !important;

    } 
</style>