<template>
    <v-card class="pas-5 mt-8 ma-5 card-shadow" rounded="0">
      <v-data-table
        id="borderless-table"
        dense
        :headers="headersUserNotificationTable"
        :items="filteredSellData"
        :loading="loading"
        loading-text="Cargando información..."
        no-data-text="No se encontró información"
        :footer-props="{ itemsPerPageText: 'Mostrar en grupo de', itemsPerPageAllText: 'Todos', pageText: '{0}-{1} de {2}' }"
        sort-by="published_at"
        :sort-desc="true"
      >
        <template v-slot:[`item.state`]="{ item }">
          <div>
            <template v-if="item.state === 1">
              <span class="mdi mdi-clock-alert-outline" style="color: #FFC107; font-size: 0.7cm;"></span>
            </template>
            <template v-else-if="item.state === 2">
              <span class="mdi mdi-check-circle-outline" style="color: #218838; font-size: 0.7cm;"></span>
            </template>
            <template v-else>
              <span class="mdi mdi-cancel" style="color: #C82333; font-size: 0.7cm;"></span>
            </template>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="row-containernewNotification">
            <v-btn icon @click="emitAction(item, 'edit')" title="Editar">
              <v-icon>mdi-file-edit-outline</v-icon>
            </v-btn>
            <v-btn icon @click="emitAction(item, 'view')" title="Visualizar">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
            <template v-if="item.state === 1 || item.state === 2" >
              <v-btn icon @click="emitAction(item, 'notification')" title="Cancelar Publicación">
                <v-icon>mdi-bell-cancel-outline</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn icon @click="emitAction(item, 'notification')" title="Volver a Publicar">
                <v-icon>mdi-bell-check-outline</v-icon>
              </v-btn>
            </template>
            <v-btn icon @click="emitAction(item, 'delete')" title="Eliminar">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </template>
  
  <script>
    import axios from '@/plugins/axios';
  const headersUserNotificationTable = [
    { text: 'ID de publicación', value: 'id' },
    { text: 'Id_usuario', value: 'user_id' },
    { text: 'Versión', value: 'version' },
    { text: 'Canal', value: 'channel' },
    { text: 'Rol', value: 'role' },
    { text: 'Hora y Fecha', value: 'published_at' },
    { text: 'Estatus', value: 'state' },
    { text: 'Acciones', value: 'actions' },
  ];
  
  export default {
    name: 'NotificationsTable',
    props: {
      filteredSellData: {
        type: Array,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        headersUserNotificationTable
      }
    },
    methods: {
  async emitAction(item, actionType) {
    if (actionType === 'cancel') {
      try {
        const response = await axios.put(`/createnotification/${item.id}/cancel`);
        if (response.status === 200) {
          item.state = 3;
        }
      } catch (error) {
        console.error('Error al cancelar la publicación:', error);
      }
    } else {
      this.$emit('on-action-click', { item, actionType });
    }
  }
}

  }
  </script>
  
  <style scoped>
  .table-row {
    height: 60px;
    background-color: rgb(240, 240, 240);
  }
  .v-data-table >>> .v-data-table-header {
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
    height: 60px;
    border-bottom: 2px solid black;
    background-color: rgb(240, 240, 240);
  }
  #borderless-table >>> div > table > tbody > tr > td {
    border-bottom: 0px;
  }
  .row-containernewNotification {
    display: flex;
    align-items: center;
  }
  .disabled-button {
    color: #bdbdbd !important;
    pointer-events: none;
  }
  </style>
  