<template> 
      <v-dialog v-model="showDialog"    :max-width="900"> 
        <v-card class="pa-1"> 
          <div class="text-left pl-3"  style="background-color: #000; color:#fff">
            Porcentaje de clientes con pago IEL | {{ title }}           
          </div>
          <div class="text-right mt-n6 mr-1" style="background-color: #000; color:#fff">
            <v-icon size="20" color="#fff" @click="showDialog = false" :disabled="loading">mdi-close-circle</v-icon>
          </div>
            <v-row class="ml-1 mr-1 mt-1 "> 
              <v-col cols="12 ">
                <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr class="table-heading">
                    <th colspan="5" class="text-center text-dark "><b>Ags. Poniente (1015)</b></th> 
                  </tr>
                  <tr class="custom-tr-no-bordered">
                    <th class="text-center text-dark "><b>Dif:&nbsp;0</b></th>
                    <th colspan="4" class="text-center text-dark "><b>Promedio:&nbsp;44%</b></th>  
                  </tr>
                  <tr class="custom-table-bordered bg-gray">
                    <th class="text-center text-dark ">May&nbsp;/&nbsp;2023</th> 
                    <th class="text-center text-dark ">Abr&nbsp;/&nbsp;2023</th> 
                    <th class="text-center text-dark ">Mar&nbsp;/&nbsp;2023</th> 
                    <th class="text-center text-dark ">Feb&nbsp;/&nbsp;2023</th> 
                    <th class="text-center text-dark ">Ene&nbsp;/&nbsp;2023</th> 
                  </tr>
                </thead>
                <tbody class="custom-table-bordered">
                  <tr >
                    <td>44%</td>
                    <td>39%</td>
                    <td>45%</td>
                    <td>50%</td>
                    <td>38%</td> 
                  </tr>  
                  
                  <tr >
                    <td class="">34</td>
                    <td class="">33</td>
                    <td class="">31</td>
                    <td class="">32</td>
                    <td class="">31</td> 
                  </tr>  
                  <tr >
                    <td class="text-success">15</td>
                    <td class="text-success">13</td>
                    <td class="text-success">14</td>
                    <td class="text-success">16</td>
                    <td class="text-success">12</td> 
                  </tr> 
                  <tr >
                    <td class="text-warning">19</td>
                    <td class="text-warning">20</td>
                    <td class="text-warning">17</td>
                    <td class="text-warning">16</td>
                    <td class="text-warning">19</td> 
                  </tr>  
                </tbody>
              </template>
            </v-simple-table>
              </v-col>
            </v-row>
            <v-btn class="ma-2" @click="showDialog = false" :disabled="loading">Cerrar</v-btn>
        </v-card>
      </v-dialog> 
</template>
<script>
export default ({
    name:'PaymentIELDetail'  ,
    props:{  
        title: {
          type: String,
          default:  ''
        }, 
        value: { 
            type: Boolean,
            default: false
          },
      },
      data: () => ({  
        loading:true, 
    }), 
    methods: { 
    },
    computed: {
      showDialog: { 
          set(value) { 
            this.$emit('input', value);
          },
          get(){
            return this.value
          }
        }
  },
  watch:{
    showDialog(){
     // if (value) this.loadSalesPerArea(this.period)
      }, 
  }
})
</script>

<style scoped>

.card-p {
  margin: 0;
  padding: 0;
  color: #000;
}

.my-table th,
.my-table td {
  border: 0px 4px 0px 0px solid #dad9d9;
  
}

.my-table {
  width: 100%;
}
.custom-border-right{
  border-right-width: 2px;
}
.table-heading {
  font-weight: bold;
  font-size: 0.75rem;
  background: rgb(245, 245, 245);
  color: #000;
  border-width: 1px 1px 3px 1px;
  border-color: rgb(228, 231, 234);
  border-bottom-color: rgb(112, 112, 112);
}
.custom-table-bordered  {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}
.custom-table-bordered>tr>td {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.custom-table-bordered>th {
  border-width: 2.5px;
  border-color: rgb(224, 228, 232);
}

.table-heading tr {
  color: #000;
}

th.boder-x-none {
  border-right: none;
  border-left: none;
}

th.boder-l-none {
  border-left: none;
}
 
.custom-table-item-sm > tr > td {
  font-size: 0.8em; 
  text-align: center;
  min-width: 50px;
}  
.custom-td-header{
  font-weight: 700;
  background-color: rgb(245, 245, 245); 
} 
.custom-text-yellow{
  color:rgb(224,168,0)
}
.custom-header-title{ 
  background-color: rgb(245, 245, 245); 
}

.custom-white-table > tr > td {
  background-color: white;
  border-width: 1px;
  border-color: lightgray;
}
.custom-table-no-bordered{  
  background-color: white;

}
.border-bottom-0{   
  border-bottom: 0px;
}
.bg-gray {
  
  background-color: rgb(245,245,245);
}
.custom-tr-no-bordered{   
  border-width: 0px 1px 0px 1px;
    border-color: lightgray;
} 
.font-size-85{
  font-size:0.85em;
}
.font-size-8{ 
  font-size:0.8em;
}
.font-size-9{ 
  font-size:0.9em;
}
.no-border{ 
  border-width:0px;
}
.bg-yellow{
  background-color:rgb(255,193,7)
}
</style>