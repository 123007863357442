<template>
    <div>
      <v-container fluid class="white lighten-5 py-7" ref="printReport">
        <div class="align-items-center">
          <h6 class="text-h6 ma-0 font-weight-bold mr-9" style="float: left;">Historial de publicaciones</h6>
          <v-select
            v-model="selectedRolFilter"
            :items="rolesWithAll"
            label="Filtrar por Rol"
            hide-details
            class="custom-select mr-4"
            style="float: left; margin-left: 170px;"
          ></v-select>
          <v-checkbox v-model="selectedCanalFilter" label="DT" value="DT" class="mr-4" style="float: left;"></v-checkbox>
          <v-checkbox v-model="selectedCanalFilter" label="PR" value="PR" class="mr-4" style="float: left;"></v-checkbox>
          <v-btn color="#FF0000" style="color: white; float: left; margin-top: 15px; margin-left: 170px;" @click="openCreateModal">Crear publicación</v-btn>
          <div style="clear: both;"></div>
        </div>
  
        <NotificationsTable
          :filteredSellData="filteredSellData"
          :loading="loading"
          @on-action-click="handleNotificationsTableClick"
        />
      </v-container>
  
      <UserNotificationForm
      v-model="showForm"
      :isEdit="isEdit"
      :roles="roles"
      :roleDisplay="roleDisplay"
      :initialData="currentNotificationData"
      @save-publication="handleSavePublication"
    />
  
      <v-snackbar v-model="error" top right class="red-snackbar-notification">
        {{ errorMessage }}
      </v-snackbar>
  
      <NotificationModalContainer 
      :showModal.sync="showModalUserNotification"
      @close="closeModal"
    >
      <NotificationModalContent
        :notificationContent="notificationContent"
        :dontShowAgain.sync="dontShowAgainUserNotification"
      />
    </NotificationModalContainer>
  
      <v-dialog v-model="deleteModalUserNotification" max-width="400px">
        <v-card>
      <v-card-text class="text-center">
        <div style="display: flex; justify-content: center; align-items: center; height: 200px;">
          <span class="mdi mdi-alpha-x-circle-outline" style="color: #C82333; font-size: 4cm;"></span>
        </div>
        <p>ID={{ deleteItemId }}</p>
        <p>¿Deseas eliminar la publicación?</p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="#FF0000" style="color: white;" @click="confirmDelete">Sí</v-btn>
        <v-btn color="grey" style="color: white;" @click="closedeleteModalUserNotification">No</v-btn>
      </v-card-actions>
    </v-card>
      </v-dialog>
  
      <v-dialog v-model="showCancelPublicationModal" max-width="400px">
  <v-card>
    <div style="display: flex; justify-content: center; align-items: center; height: 200px;">
      <span class="mdi mdi-alert-circle-outline" style="color: #FACEA8; font-size: 4cm;"></span>
    </div>
    <v-card-text>¿Deseas cancelar la publicación?</v-card-text>
    <v-card-actions>
      <v-btn color="#FF0000" @click="confirmCancelPublication">Sí</v-btn>
      <v-btn color="grey" @click="showCancelPublicationModal = false">No</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
  
      <v-dialog v-model="restorePublicationModal" max-width="400px">
    <v-card>
      <div style="display: flex; justify-content: center; align-items: center; height: 200px;">
        <span class="mdi mdi-check-circle-outline" style="color: #28a745; font-size: 4cm;"></span>
      </div>
      <v-card-text>¿Deseas restaurar la publicación?</v-card-text>
      <v-card-actions>
        <v-btn color="#28a745" @click="confirmRestorePublication">Sí</v-btn>
        <v-btn color="grey" @click="restorePublicationModal = false">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import axios from '@/plugins/axios';
  import NotificationsTable from '@/components/tables/UserNotificationsTable';
  import UserNotificationForm from '@/components/forms/UserNotificationForm';
  import NotificationModalContent from '@/components/SystemUpdatesList';
  import NotificationModalContainer from '@/components/NotificationModalContent';
  
  export default {
    name: 'Notification',
    components: {
      NotificationsTable,
      UserNotificationForm,
      NotificationModalContainer,
      NotificationModalContent,
    },
    data() {
      return {
        loading: false,
        sellData: [],
        errorMessage: '',
        error: false,
        selectedRolFilter: '',
        selectedCanalFilter: [],
        isEdit: false,
        showForm: false,
        currentNotificationId: null,
        currentNotificationData: null,
        roleOrder: ['Cliente', 'Asesor', 'Gerente', 'Subgerente', 'Gerente Regional', 'Subdirector', 'Director'],
        roles: [ 'Director','Subdirector','Gerente Regional','Gerente','Asesor','Cliente', 'Todos'],
        roleDisplay: {
          Customer: 'Cliente',
          Seller: 'Asesor',
          Manager: 'Gerente',
          Director: 'Director',
          all: 'Todos',
          Subdirector: 'Subdirector',
          Submanager : 'Subgerente',
          RegionalManager: 'Gerente Regional'
        },
        showModalUserNotification: false,
        dontShowAgainUserNotification: false,
        notificationContent: '',
        deleteModalUserNotification: false,
        deleteItemId: null,
        showCancelPublicationModal: false,
        publicationToCancel: null,
        restorePublicationModal: false,
      publicationToRestore: null,
      };
    },
    mounted() {
      this.checkUserNotification();
      this.fetchCreateNotification();
    },
    computed: {
      filteredSellData() {
        return this.sellData.filter(item => {
          const matchesRole = this.selectedRolFilter === 'Todos' || !this.selectedRolFilter ? true : item.role === this.selectedRolFilter;
          const matchesChannel = this.selectedCanalFilter.length > 0
            ? this.selectedCanalFilter.some(channel => item.channel.includes(channel))
            : true;
          return matchesRole && matchesChannel;
        }).map(item => {
          const rolesArray = item.role.split(','); 
          item.role = rolesArray
          .map(role => this.roleDisplay[role] || role)
          .sort((a, b) => this.roleOrder.indexOf(a) - this.roleOrder.indexOf(b))
          .join(', '); 
          return item;
        });
      },
      rolesWithAll() {
        return ['Todos', ...this.roles];
      },
      rolesForSelect() {
      return Object.entries(this.roleDisplay).map(([value, text]) => ({ value, text }));
      }
    },
    methods: {
      ...mapActions({
        sendToPDF: 'printer/sendToPDF'
      }),
      async fetchCreateNotification() {
        this.loading = true;
        try {
          const response = await axios.get(`/user/createnotification`);
          this.sellData = response.data;
  
          const currentDateTime = new Date();
          this.sellData.forEach(item => {
            if (currentDateTime - new Date(item.published_at) >= 24 * 60 * 60 * 1000) {
              item.state = 3;
            } else if (currentDateTime >= new Date(item.published_at)) {
              item.state = 2;
            }
          });
        } catch (error) {
          this.errorMessage = 'Ocurrió un problema al recuperar la información.';
          this.error = true;
          console.error('Error fetching nuevas Notificaciones:', error);
        }
        this.loading = false;
        this.sendToPDF({ active: true });
      },
      openCreateModal() {
        this.isEdit = false;
        this.currentNotificationId = null;
        this.currentNotificationData = null;
        this.toggleModal();
      },
      handleSavePublication(payload, isEdit) {
        if (isEdit) {
          this.editPublication(payload);
        } else {
          this.createPublication(payload);
        }
      },
      async createPublication(payload) {
        try {
          const userId = this.$store.getters['auth/user'].id;
          await axios.post(`/createnotification`,  { ...payload, user_id: userId });
          this.fetchCreateNotification();
        } catch (error) {
          this.errorMessage = 'Ocurrió un problema al guardar la publicación.';
          this.error = true;
          console.error('Error saving publication:', error);
        }
      },
      async editPublication(payload) {
        try {
          await axios.put(`/createnotification/${this.currentNotificationId}`, payload);
          this.fetchCreateNotification();
        } catch (error) {
          this.errorMessage = 'Ocurrió un problema al guardar la publicación.';
          this.error = true;
          console.error('Error saving publication:', error);
        }
      },
      editUserNotificationAction(item) {
        this.isEdit = true;
        this.currentNotificationId = item.id;
        this.currentNotificationData = {
          id: item.id,
          role: item.role, 
          channel: item.channel,
          published_at: item.published_at,
          content: item.content,
        };
        this.toggleModal();
        this.editor.commands.setContent('');
      },
      closeEditModal() {
    this.isEdit = false;
    this.currentNotificationId = null;
    this.currentNotificationData = null; 
    this.showForm = false;
  },
  toggleModal() {
        this.showForm = !this.showForm;
        if (this.showForm && !this.isEdit) {
        this.currentNotificationData = null;
      }
      },
  resetShowForm() {
        this.showForm = true; 
      },
      viewUserNotificationAction(item) {
        this.notificationContent = item.content;
        this.showModalUserNotification = true;
      },
      closeModal() {
        if (this.dontShowAgainUserNotification) {
          localStorage.setItem(`dontShowNotification-${this.currentNotificationId}`, 'true');
        }
        this.showModalUserNotification = false;
      },
      opendeleteModalUserNotification(item) {
    this.deleteItemId = item.id;
    this.deleteModalUserNotification = true;
  },
  cancelPublicationModal(item) {
    this.publicationToCancel = item;
    this.showCancelPublicationModal = true;
  },
  closedeleteModalUserNotification() {
    this.deleteModalUserNotification = false;
  },
  async confirmDelete() {
    try {
      await axios.put(`/createnotification/${this.deleteItemId}/deactivate`);
      this.deleteModalUserNotification = false;
      this.fetchCreateNotification();
    } catch (error) {
      this.errorMessage = 'Ocurrió un problema al eliminar la publicación.';
      this.error = true;
      console.error('Error deleting publication:', error);
    }
  },
  notificationAction(item) {
    if (item.state === 3) {
      this.publicationToRestore = item;
      this.restorePublicationModal = true;
    } else {
      this.publicationToCancel = item;
      this.showCancelPublicationModal = true;
    }
  },
  async confirmCancelPublication() {
  if (this.publicationToCancel) {
    try {
      await axios.put(`/createnotification/${this.publicationToCancel.id}/updateState`, { state: 2 });
      this.fetchCreateNotification();
    } catch (error) {
      this.errorMessage = 'Ocurrió un problema al cancelar la publicación.';
      this.error = true;
      console.error('Error canceling publication:', error);
    }
  }
  this.showCancelPublicationModal = false;
},
async confirmRestorePublication() {
  if (this.publicationToRestore) {
    try {
      const response = await axios.get('/getversion');
      if (response.data && response.data.version) {
        const currentDateTime = new Date();
        const offsetMs = currentDateTime.getTimezoneOffset() * 60000; // Offset en milisegundos

        const formattedDateTime = new Date(Date.now() - offsetMs)
          .toISOString()
          .slice(0, 19)
          .replace('T', ' ');

        const payload = {
          ...this.publicationToRestore,
          published_at: formattedDateTime,
          version: response.data.version.version,
        };

        await axios.post('/createnotification', payload);
        this.fetchCreateNotification();
      }
    } catch (error) {
      this.errorMessage = 'Ocurrió un problema al restaurar la publicación.';
      this.error = true;
      console.error('Error restoring publication:', error);
    }
  }
  this.restorePublicationModal = false;
},

  handleNotificationsTableClick({ item, actionType }){
   switch(actionType){
   case 'edit': this.editUserNotificationAction(item); break;
   case 'view': this.viewUserNotificationAction(item); break;
   case 'notification': this.notificationAction(item); break;
   case 'delete': this.opendeleteModalUserNotification(item); break;
   case 'cancel': this.cancelPublicationModal(item); break;
   }
  },
  async checkUserNotification() {
    const userRole = this.$store.state.userRole;
    try {
      const currentDateTime = new Date();
      const response = await axios.get(`/user/createnotification`);
      const notifications = response.data;
  
      for (const notification of notifications) {
        const publicationDateTime = new Date(notification.published_at);
  
        if (publicationDateTime <= currentDateTime &&
          currentDateTime - publicationDateTime <= 24 * 60 * 60 * 1000 &&
          (notification.role === userRole || notification.role === 'all')) {
  
          const dontShow = localStorage.getItem(`dontShowNotification-${notification.id}`);
  
          if (!dontShow) {
            this.notificationContent = notification.content;
            this.currentNotificationId = notification.id;
            this.showModalUserNotification = true;
            break;
          }
        }
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  },
  },
  };
  </script>
  
  <style scoped>
  
  #borderless-table >>> div > table > tbody > tr > td {
    border-bottom: 0px;
  }
  .row-containernewNotification {
    display: flex;
    align-items: center;
  }
  .custom-select {
    width: 6cm; 
  }
  
  .red-snackbar-notification {
  color: red;
  }
  
  .black-margin-user-notification {
    margin: 10px; 
    background-color: rgb(240, 240, 240);
    padding: 10px; 
  }
  
  </style> 
  