<template>
  <div> 
    <v-card>
        <v-card-text class="pa-1">
    <div class="details--content">      
      <div class=" elevation-2">
          <div class="modal--head">
              Detalles de Pedido de Productos Nuevos
              <a @click="close()">
                  <v-icon color="white darken-2">
                      mdi-close-circle
                  </v-icon>
              </a>
          </div>
          <v-simple-table  border="1" class="details--table">
              <template v-slot:default>                  
                  <tbody>
                      <tr>
                          <td>Antiguedad (Mayor o igual a 12 meses)</td>
                          <td> {{ rules.seniorityValue }} Meses   </td>
                          <td>
                            <v-icon :color="rules.seniorityRule ? 'green' : 'red'">
                                {{rules.seniorityRule ? 'mdi-check-circle': 'mdi-close-circle' }}
                            </v-icon>
                          </td>
                      </tr>
                      <tr>
                          <td>Promedio Ponderado (Menor o igual a 38)</td>
                          <td>{{wallet?.PPDP}} Días  </td>
                          <td>
                            <v-icon :color="rules?.PPDP ? 'green' : 'red'">
                                {{ rules.PPDP ? 'mdi-check-circle': 'mdi-close-circle' }}
                            </v-icon>
                          </td>
                      </tr>
                      <tr>
                          <td>Renglonaje Anterior (Mayor o igual a 20)</td>
                          <td>{{wallet?.RTRIMA}}</td>
                          <td>
                            <v-icon :color="rules.RTRIMA ? 'green' : 'red'">
                                {{ rules.RTRIMA  ? 'mdi-check-circle': 'mdi-close-circle' }}
                            </v-icon>
                          </td>
                      </tr>
                      <tr>
                          <td>PNC Hechos en el trimestre (Cero)</td>
                          <td>{{wallet?.PSNW}} al mes</td>
                          <td>
                            <v-icon :color="rules?.PSNW ? 'green' : 'red'">
                                {{ rules.PSNW  ? 'mdi-check-circle': 'mdi-close-circle' }}
                            </v-icon>
                          </td>
                      </tr>
                     <tr>
                        <td>Sin saldos / facturas vencidas</td>
                        <td></td>
                        <td>
                            <v-icon :color="rules?.AUFSD ? 'green' : 'red'">
                                {{ rules?.AUFSD ? 'mdi-check-circle': 'mdi-close-circle' }}
                            </v-icon>
                          </td>
                      </tr> 
                  </tbody>
              </template>
          </v-simple-table>
      </div>    
    </div> 
</v-card-text> 
    </v-card>   
  </div>
</template>

<script>
/* eslint-disable */ 
 
import { mapGetters } from "vuex";
 
export default {
    props: ['wallet','rules'], //si no se sabe la info del customer. Enviar en false
    data(){
        return {
            active: false,
            seniority: 0,
            customerRules:{}
        }
    },
    watch:{
        
    }, 
    computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
    methods:{
        /*
        getRules(){
            if (this.customer) {
            const erdat = moment(this.customer.ERDAT, 'YYYY-MM-DD');
            const today = moment();
            const seniority = today.diff(erdat, 'month');  
            this.seniority = seniority;
            this.customerRules.seniority = seniority >= 12 ?true: false
            this.customerRules.PPDP = parseInt(this.customer.PPDP) <= 38 ?true: false
            this.customerRules.RTRIMA = parseInt(this.customer.RTRIMA) >= 20 ?? false
            this.customerRules.RSR6M = parseInt(this.customer.RSR6M) == 0 ?? false //validar PPN
            this.customerRules.AUFSD = parseInt(this.customer.AUFSD) == 0 ?? false;
            var PNCStatus = 0;
            if (
                this.customerRules.seniority &&
                this.customerRules.PPDP &&
                this.customerRules.RTRIMA &&
                this.customerRules.RSR6M &&
                this.customerRules.AUFSD
                ) {
                    PNCStatus = '#28A745';
                } else {
                    PNCStatus = 'warning';
                }
                this.$emit('PNCStatus',PNCStatus)
            }else{
                axios
                .post("getCustomerInformation", {
                client_code: this.user.custumer_code,
                society: "217",
                organization:this.user.VKORG,
                channel:this.user.VTWEG,
                })
                .then((response) => {
                this.customer = response.data; 
                });
            }
              
        },*/
        close(){
            this.$emit('close-reorder-products',true)
        },
    }
}
</script>

<style scoped> 
    .details--table{
        min-width: 400px;
        border: thin solid rgba(0,0,0,.12);
    }
    .details--table thead{
        background: #f2f2f2;        
    }
    .details--table thead th, .details--table tbody td{
        height: 35px !important;
        text-align: left;
    }

    .modal--head{
        background: #000;
        color: #fff;
        font-size: 16px;       
        padding-left: 5px;
        display: flex;
        justify-content: space-between;
    }    
</style>