<template>
<v-row class="mt-0 ml-3 mr-3">
    <v-col cols="12" class="pb-0 pt-0">
         <search-customer-seller :showGoback="false"/>   
    </v-col>
    <v-col cols="12" class="pb-0 pt-0">
        <average-orders 
        :chnl="chnl"
        :regionalManager="regionalManager"   />
    </v-col>
    <v-col cols="12" class="pb-0 pt-0">
        <new-products 
        :chnl="chnl"
        :regionalManager="regionalManager"   />
    </v-col>
    <v-col cols="12" class="pb-0 pt-0">
        <new-products 
        :chnl="chnl" 
        title="FACTURACIÓN PRODUCTOS GENÉTICA N" 
        :isGen="1" 
        :regionalManager="regionalManager"  />
    </v-col>
    <v-col cols="12" class="pb-0"> 
        <div class="d-flex flex-wrap ma-0 w-100" style="box-shadow: inset 0px 0px 8px #FE080829, 0px 0px 6px #F8090929;"> 
            <group-by-menu  
             @change="changeFilter" 
             defaultGroupBy="Zona"
             :chnl = "chnl" 
             />  
          </div>
    </v-col>
    <v-col cols="12" class="pb-0 pt-0">
        <seller-list 
        :chnl="chnl" 
        :regionalManager="regionalManager"
        :period="periodDate" 
        />
    </v-col>
</v-row>
</template>

<script>
 import { mapGetters } from "vuex";
 import AverageOrders from "./AverageOrders.vue";
 import NewProducts from "./NewProducts.vue";
 import SellerList from "@/views/diary/director/zone/SellerList.vue";
 import SearchCustomerSeller from "@/components/SearchCustomerSeller.vue";
 import GroupByMenu from "../components/GroupByMenu.vue";
 import MixinService from '@/mixins/service'
 import MixinHelpers from '@/mixins/helpers';
 import MixinDates from '@/mixins/dates';  
 export default {
     name: "DirectorHome",
     components: {
         AverageOrders,
         NewProducts,
         SellerList,
         SearchCustomerSeller, 
         GroupByMenu
     },
    mixins:[MixinService,MixinHelpers,MixinDates],
     props: { 
         chnl:{
          required:true,
          String
         },
         regionalManager:{
          required:true,
      }
     },
     data: () => ({
         periodDate:[],
         months:[], 
     }),
     watch: {},
     methods: {  
        changeFilter(data){ 
            this.periodDate =  data.period, 
            this.$emit('groupByChange', data)
        }, 
     },
     mounted(){
        const lastMonth = this.calcularUltimosMeses(1);
        this.periodDate =  this.periodFormat(lastMonth[0]); 
      },
     computed: {
         ...mapGetters({
             authenticated: "auth/authenticated",
             user: "auth/user",
         }),
        
     }
 };
</script>
