<template>
  <div class="d-flex flex-sm-row flex-wrap">
    <div class="pa-2 pl-10 d-flex flex-wrap">
      <div class="mt-2 mr-1 "> Agrupar por :</div>
      <div class="mt-2" style="width: 180px;">
        <custom-gray-select @input="changeFilter" :disabled="disabled" style="width: 100%;" v-model="group" :options="groupByOptions" />
      </div>
    </div>
    <div class="pa-2 pl-10 d-flex flex-wrap">
      <div class="mt-2 mr-1 ">Periodo:</div>
      <div class="mt-2">
        <custom-gray-select @input="changeFilter"  :disabled="disabled" v-model="periodDate" :options="optionPeriods" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomGraySelect from "@/components/CustomGraySelect.vue";
import MixinService from '@/mixins/service'
import MixinHelpers from '@/mixins/helpers';
import MixinDates from '@/mixins/dates';

export default {
  name: "GroupByMenu",
  props :{ 
    defaultGroupBy: {
      default: ''
      },
      chnl:{
        default:'PR'
      },
      disabled:{
        default:false
      }
  },
  components: {
    CustomGraySelect,
  },
  data: () => ({
    periodDate: [], 
    months: [], 
    period: '', 
    groupByOptions: [], 
    group:"",
    groupBy: 
      {
        directorPR:['Gerencia Regional','Sub Gerentes','Zona'],
        directorDT:['Gerencia Regional','Gerentes','Zona'],
        rgManagerPR:['Sub Gerentes','Zona'],
        rgManagerDT:['Gerentes','Zona',],
      } 
  }),
  mixins: [MixinService, MixinHelpers, MixinDates],
  watch: {},
  methods: {
    changeFilter() {
      const data = {
        groupBy: this.group,
        period:  this.removeTimeFromDate(this.periodDate,'MMM YYYY','YYYYMM'),
        headerTitle : this.group 
      } 
      this.$emit('change', data)
    },
    setGroupByOptions() {
      const role = this.getnavRole();
      const gropBy = `${role}${this.chnl}`; 
      console.log(gropBy);
      this.groupByOptions = this.groupBy[gropBy]; 
      this.group = this.defaultGroupBy ? this.defaultGroupBy : this.groupByOptions[0];
      this.$emit('headerTitle',this.group);
      },  
    getnavRole(){
      switch (this.$route.meta.role) {  
        case "Director":   
        case "IELDirector":    
        case "IELCommercialPR":   
        case "IELCommercialDT":   
        case "director":
          return "director";
        case "Regional": 
        case "RegionalManager":  
        case "IELRegManager": 
          return "rgManager"; 
      } 
    },
  },
  created() { 
    this.setGroupByOptions()
  },
  mounted() {
    this.months = this.calcularUltimosMeses(13);
    this.periodDate = this.months[0].date;
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    optionPeriods() {
      return this.arrayPluck(this.months, 'date');
    },
  }

};
</script>
