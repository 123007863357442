<template>
    <v-card class="m-4" elevation="0">
      <v-card-title>Historial de Solicitudes</v-card-title>
      <v-card-text>
        <v-row>
            <v-col>
                <v-text-field v-model="search" prepend-icon="mdi-magnify" @keyup="onKeyup" clearable 
                @click:clear="clearSearch" label="Escribe para filtrar" dense />
            </v-col>
            <v-col>
                <v-select :items="customerstatus" item-text="status" item-value="id" label="Selecciona el status para filtrar" dense @change="filterStatus" v-model="statusId" clearable />
            </v-col>
        </v-row>
        <iel-table :headers="headers">
          <tr v-if="requests.length <= 0">
            <iel-table-cell :cols="10">No hay registros</iel-table-cell>
          </tr>
          <tr v-for="request in requests" :key="`request-${request.id}`">
            <iel-table-cell>{{ request.channel }}</iel-table-cell>
  
            <iel-table-cell>{{ request.custumer_code }}</iel-table-cell>
  
            <iel-table-cell>
              <a :href="`${url}/customersfiles/${request.id}/envelope`">{{ request.envelope_id }}</a>
            </iel-table-cell>
  
            <iel-table-cell>{{ request.person_type }}</iel-table-cell>
  
            <iel-table-cell>{{ request.created_at }}</iel-table-cell>
  
            <iel-table-cell>{{ request.company_name }}</iel-table-cell>
  
            <iel-table-cell>{{ request.rfc }}</iel-table-cell>
  
            <iel-table-cell>{{ request.a_estate }}</iel-table-cell>
  
            <iel-table-cell>
              <v-chip
                class="ma-2"
                color="primary"
                small
                v-if="request.customer_status.status == 'Revisión Crédito'"
              >
                {{ request.customer_status.status }}
              </v-chip>
              <v-chip
                class="ma-2"
                small
                v-else
              >
                {{ request.customer_status.status }}
              </v-chip>
            </iel-table-cell>
  
            <iel-table-cell>
              <v-btn small icon type="success" @click="goToDetail(request.id)">
                  <v-icon>mdi-eye</v-icon>
              </v-btn>
            </iel-table-cell>
          </tr>
        </iel-table>
        <div class="text-center my-2">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.to"
          />
        </div>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import { debounce } from "@/utilities/helpers"
  import IelTable from "@/components/tables/IElTable.vue";
  import IelTableCell from "@/components/tables/IELTableCell.vue";
  import { mapActions, mapGetters, mapMutations } from "vuex";
  
  export default {
    name: "CreditHistory",
    components: { IelTable, IelTableCell },
  
    data: () => ({
      url: '',
      search: '',
      headers: [
        { text: "canal" },
        { text: "Número cliente" },
        { text: "Transacción ID" },
        { text: "Tipo" },
        { text: "Fecha solicitud" },
        { text: "Razón social" },
        { text: "RFC" },
        { text: "Estado" },
        { text: "Estatus" },
        { text: "Acciones" },
      ],
      statusId: null,
    }),
  
    computed: {
      ...mapGetters('customersrequest', { 
        requests: "allRequests", 
        pagination: "pagination", 
        customerstatus: "customerstatus",
      }),
    },
  
    watch: {
      'pagination.page'(value) {
        this.loadData(value)
      }
    },
  
    mounted() {
      this.url = this.$filesUrl;
      this.debounceFn = debounce(() => this.loadData(1, this.search), 800)
      this.loadData(1);
      this.loadCustomerStatus();
    },
  
    methods: {
      ...mapMutations({
        setPage: "customersrequest/SET_PAGE",
      }),
      ...mapActions('customersrequest', [ 'loadCustomerRequest', 'loadCustomerStatus' ]),
      onKeyup() {
        this.debounceFn()
      },
      loadData(page, search = "") {
  
        this.loadCustomerRequest({status: this.status, search, page});
      },
      goToDetail(customerId) {
        this.$router.push(`/credit/customers-request/${customerId}`);
      },
      clearSearch() {
        this.setPage(1);
        this.loadData(1);
      },
      filterStatus() {
        this.loadCustomerRequest({ status: this.statusId, search: null, page: 1})
      }
    },
  };
  </script>
  
