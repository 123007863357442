<template>
  <div class="container-fluid ">
    <CardTitle title="DATOS DE CONTACTO IUSA" icon="clock-time-four-outline">
        <template v-slot:content> 
          <div class="box-shadow font-size-x pa-5" v-if="!loading">

          <v-row >
          <v-col cols="12" sm="5" md="4" class="text-left pa-0 pl-3"> <span class="text-center"> <b> Vendedor:</b>  {{ contacts?.NAME1  }} </span> </v-col>

          <v-col cols="12" sm="3" md="4" class="text-mobile-align-center pa-0 pl-3"> <span class="text-center font-weight-black">Tel:&nbsp;{{ phoneFormat(contacts.PCELU) || "Sin asignación" }}</span> </v-col>
          <v-col cols="12" sm="4" md="0" class="text-left pa-0 pl-3"> <span><b class="font-weight-black">e-mail:</b>&nbsp;<a :href="`mailto:${contacts.PEMAIL}`">{{ contacts.PEMAIL || "Sin asignación"  }} </a> </span>  </v-col>
        </v-row>  
        <v-row  v-if="isPr">
          <v-col cols="12" sm="5" md="4" class="text-left pa-0 pl-3"> <span class="text-center"> <b> Subgerente:</b>  {{ contacts?.NOMSGER  }} </span> </v-col>
          <v-col cols="12" sm="3" md="4" class="text-mobile-align-center pa-0 pl-3"> <span class="text-center font-weight-black">Tel:&nbsp;{{ phoneFormat(contacts.SGCELU) || "Sin asignación" }}</span> </v-col>
          <v-col cols="12" sm="4" md="0" class="text-left pa-0 pl-3"> <span><b class="font-weight-black">e-mail:</b>&nbsp;<a :href="`mailto:${contacts.SGEMAIL}`">{{ contacts.SGEMAIL || "Sin asignación"  }} </a> </span>  </v-col>
        </v-row>
        <v-row  v-else>
          <v-col cols="12" sm="5" md="4" class="text-left pa-0 pl-3"> <span class="text-center"> <b> Gerente:</b>  {{ contacts?.NOMGER  }} </span> </v-col>
          <v-col cols="12" sm="3" md="4" class="text-mobile-align-center pa-0 pl-3"> <span class="text-center font-weight-black">Tel:&nbsp;{{ phoneFormat(contacts.GCELU) || "Sin asignación" }}</span> </v-col>
          <v-col cols="12" sm="4" md="0" class="text-left pa-0 pl-3"> <span><b class="font-weight-black">e-mail:</b>&nbsp;<a :href="`mailto:${contacts.GEMAIL}`">{{ contacts.GEMAIL || "Sin asignación"  }} </a> </span>  </v-col>
        </v-row>
        <v-row >
          <v-col cols="12" sm="5" md="4" class="text-left pa-0 pl-3"> <span class="text-center"> <b> Regional:</b>  {{ contacts?.NOMGERR  }} </span> </v-col>
          <v-col cols="12" sm="3" md="4" class="text-mobile-align-center pa-0 pl-3"> <span class="text-center font-weight-black">Tel:&nbsp;{{ phoneFormat(contacts.GRCELU) || "Sin asignación" }}</span> </v-col>
          <v-col cols="12" sm="4" md="0" class="text-left pa-0 pl-3"> <span><b class="font-weight-black">e-mail:</b>&nbsp;<a :href="`mailto:${contacts.GREMAIL}`">{{ contacts.GREMAIL || "Sin asignación"  }} </a> </span>  </v-col>
        </v-row>     
        <v-row class="">
          <v-col cols="12" sm="5" md="4" class="text-left pa-0 pl-3"> <span class="text-center"><b>  Servicio post venta:</b> </span> </v-col> 
          <v-col cols="12" sm="3" md="4" class="text-mobile-align-center pa-0 pl-3"> <span class="text-center font-weight-black">Tel: 55&nbsp;5118&nbsp;1585</span> </v-col>
          <v-col cols="12" sm="4" md="0" class="text-left pa-0 pl-3 pb-3" > <span><b class="font-weight-black">e-mail:</b> <a href="mailto:servicedesk@iusa.com.mx">serviciopostventa@iusa.com.mx</a>  </span>  </v-col>
        </v-row>
      </div>  
      <div v-else>
        <v-progress-linear :active="loading" :indeterminate="loading" top color="deep-purple accent-4" />

      </div>
        </template>
      </CardTitle>
  </div>
</template>

<script> 
import CardTitle from './CardTitle.vue'
import axios from "@/plugins/axios";

export default {
  props: {
    generalInfoCustomer:{
      default:{}
    }
  },
  watch:{
    generalInfoCustomer(){  
     this.loadContacts();
    }
  },
  name: "ContactDesk",
  data: () => ({
    contacts:[], 
    loading:true,
  }),
  components:{
    CardTitle
  },
  computed: { 
    isPr(){
      return this.generalInfoCustomer.VTWEG!='DT';
    }
  },
  methods: {
    async loadContacts(){
      try {
        this.loading =true;
        const params = {
            P_USER:this.generalInfoCustomer.KUNNR
          };
        const result = await axios.post('cover/contacts',params);
        this.contacts = result.data;
        this.loading = false;

      } catch (error) {
        this.loading = false;
        console.error(error);
      }
     
    }, 
    phoneFormat(phone) {
    // Convierte el número a una cadena
    const phoneStr = phone.toString(); 
    return phoneStr.replace(/(\d{4})(\d{4})(\d{2})/, '$1 $2 $3'); 
  }
  },
};
</script>

<style scoped>
.box-shadow{
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  opacity: 1;
}
.font-size-x {
  font-size: 14px;
}  
@media (max-width: 600px) { 
  .font-size-x {
  font-size: 11px;
}

.text-mobile-align-center {
    text-align: left !important;
  }
.flex-col{
     flex-direction: column; 
}
}

@media (min-width: 601px) and (max-width:960px) { 
  .font-size-x {
  font-size: 12px;
}  

.text-mobile-align-center {
    text-align: center !important;
  }

}
</style>
