const filesUrl = process.env.VUE_APP_FILES_URL;

export const menuDropdown = [
  {
    text: "Carátula",
    icon: "mdi-account-box-edit-outline",
    roles: ['Administrator'],
    show: false, // If false, path will be hidden to roles/chaneles setted in this object, if true (default value) it will be only shown to those roles/channels

    menu: [
      {
        text: "Carátula del cliente",
        path: "Home",
        roles: ['Customer', 'CustomerPR'],
      },
      {
        text: "Carátula del cliente",
        path: "CustomerCoverDetails",
        notRoles: ['Customer', 'CustomerPR'],
      },      
    ],
  },
  {
    text: "Pedidos",
    icon: "mdi-cart-plus",
    menu: [
      {
        text: "Nuevo pedido",
        path: "NewShoppingCart",
        params: { doctype: "PSDT", idq: 0 },
        channels: ['DT'],
        notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
      },
      {
        text: "Nuevo pedido",
        path: "NewShoppingCartPR",
        params: { doctype: "PSIU", idq: 0 },
        channels: ['PR'],
        notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
      },
      {
        text: "Pedidos especiales",
        menu: [
          {
            text: "Carretes",
            path: "CreateReelOrder",
            roles: ["CustomerPR", 'Customer', "Seller"],
          },
          {
            text: "Combo",
            path: "CreateKitOrder",
            roles: ["CustomerPR", "Customer", "Seller"],
          },
          {
            text: "Pedidos atipicos",
            path: "",
            notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
          },
          {
            text: "Productos Nuevos PNC",
            path: "NewShoppingCart",
            params: { doctype: "PSNW", idq: 0 },
            channels: ['DT'],
            notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
          },
          {
            text: "Productos Nuevos PNC",
            path: "NewShoppingCartPR",
            params: { doctype: "PSNW", idq: 0 },
            channels: ['PR'],
            notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
          },
          {
            text: "Pedidos bajo cotiza",
            path: "",
            roles: ["Seller"],
          },
          {
            text: "Bono POP",
            menu: [
              {
                text: "Artículos promocionales",
                path: "NewShoppingCart",
                params: { doctype: "PSPO", idq: 0 },
                channels: ['DT'],
                notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
              },
              {
                text: "Artículos promocionales",
                path: "NewShoppingCartPR",
                params: { doctype: "PSPO", idq: 0 },
                channels: ['PR'],
                notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
              }
            ],
            
          },
          {
            text: "Pedidos bajo cotiza",
            path: "CreateRequestForQuotation",
            roles: ["Seller", "Customer", "CustomerPR"],
          },
          {
            text: "Productos con descuento de liquidación",
            path: "DiscontinuedMaterial",
            channels: ['DT'],
            notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
          },
          {
            text: "Productos con descuento de liquidación",
            path: "DiscontinuedMaterialPR",
            channels: ['PR'],
            notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
          },
          {
            text: "Tienda nueva",
            path: "",
            notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
          },
        ],
        notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
      },
      {
        text: "Cotización",
        path: "QuoterHistory",
        notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
      },
      {
        text: "Estado de pedidos",
        path: "OrderStatus",
      },
      {
        text: "Historial de pedidos",
        path: "HistoryOrders",
      },
      {
        text: "Recordatorio carretes",
        path: "CreateReelReminderOrder",
        roles: ["CustomerPR", "Customer", "Seller"],
      },
      {
        text: "Movimientos de cuenta",
        path: "AccountStatus",
        notRoles: ['Director', 'IELDirector','IELCommercialPR', 'IELCommercialDT','Subdirector'],
      },
    ],
  },
  {
    text: "Consulta",
    icon: "mdi-layers-search-outline",
    menu: [
      {
        text: "Videoteca",
        path: "MultimediaCenterVideo",
      },
      {
        text: "Fotos e imágenes digitales",
        path: "",
      },
      {
        text: "Centros de servicio",
        path: "",
      },
      {
        text: "Diagramas, manuales y fichas técnicas",
        path: "",
      },
      {
        text: "Formatos (selectivo)",
        path: "",
        roles: ["Seller"],
      },
      {
        text: "Promociones",
        path: `${filesUrl}/documentation/Promo_IUSA_Noviembre_24_distribucion.pdf`,
        external: true,
        channels: ['PR'],
      },
      {
        text: "Promociones",
        path: `${filesUrl}/documentation/Promo_IUSA_Noviembre_24_minoristas.pdf`,
        external: true,
        channels: ['DT'],
      },
      {
        text: "Catálogos",
        menu: [
          {
            text: "Catálogo Construcción",
            path: "https://www.iusa.com.mx/catalogo_construccion/",
            external: true,
          },
          {
            text: "Catálogo Energia",
            path: "https://www.iusa.com.mx/catalogo_energia/",
            external: true,
          },
        ],
      },
    ],
  },
  {
    text: "Devoluciones",
    icon: "mdi-tag-arrow-right-outline",
    menu: [
      {
        text: "Evaluación de calidad",
        path: "",
        roles: ["Seller"],
      },
      {
        text: "Consulta de evaluación de calidad",
        path: "",
      },
      {
        text: "Evaluación de servicio",
        path: "",
      },
      {
        text: "Consulta de evalución de servicio",
        path: "",
      },
      {
        text: "Nueva devolución de prod. en buen estado",
        path: "",
        roles: ["Seller"],
      },
      {
        text: "Consulta de devolución prod. en buen estado",
        path: "",
      },
      {
        text: "Productos con devolución autorizada",
        path: "",
        roles: ["Seller"],
      },
    ],
  },
  {
    text: "Publicidad y Promoción",
    icon: "mdi-percent-outline",
    menu: [
      {
        text: "Solicitud de rotulación ",
        path: "",
        roles: ["Seller"],
      },
      {
        text: "Consulta de solicitud de rotulación",
        path: "",
      },
      {
        text: "Solicitud de exhibidores",
        path: "",
        roles: ["Seller"],
      },
      {
        text: "Consulta de solicitud de exhibidores",
        path: "",
      },
      {
        text: "Solicitud de POP",
        path: "",
        roles: ["Seller"],
      },
      {
        text: "Consulta de solicitud de POP",
        path: "",
      },
      {
        text: "Estudios de mercado",
        path: "",
      },
      {
        text: "Fotos de tiendas Top",
        path: "",
      },
    ],
  },
  {
    text: "Actualización de precios y Productos",
    icon: "mdi-alert-circle-check-outline",
    menu: [
      {
        text: "Archivo de precios",
        path: "MultimediaCenter",
      },
      {
        text: "Familias que suben de precio",
        path: "",
      },
      {
        text: "Familias que bajan de precio",
        path: "",
      },
      {
        text: "Inner, master y pallet de productos",
        path: "",
      },
      {
        text: "Disponibilidad de productos escasos",
        path: "",
      },
      {
        text: "Arribo de productos nuevos",
        path: "",
      },
      {
        text: "Códigos sustituidos por nuevo modelo",
        path: "",
      },
    ],
  },
];
