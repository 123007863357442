<template>
    <v-card>
    <system-updates-list
      :notification-content="notificationContent"
      :dont-show-again.sync="dontShowAgain"
      @close="closeModalNotification"
    />
  </v-card>
  </template>
  
  <script>
  import SystemUpdatesList from '@/components/SystemUpdatesList';
  import { mapGetters } from 'vuex';
  
  export default {
  
    components: {
      SystemUpdatesList,
    },
  
    computed: {
      ...mapGetters('userNotification', [
        'notificationContent',
        'dontShowAgain',
      ]),
    },
  
  
    methods: {
      closeModalNotification() {
        this.closeModalNotification();
      },
    },
  };
  </script>
  
