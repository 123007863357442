<template>
    <div> 
      <v-dialog v-model="showDialog"  :persistent="true" :max-width="900"> 
        <v-card class="pa-1">
        <div class="text-left pl-3"  style="background-color: #000; color:#fff">
            Participación / Divisiones - {{ title }}           </div>
          <div class="text-right mt-n6 mr-1" style="background-color: #000; color:#fff">
            <v-icon size="20" color="#fff" @click="showDialog = false" :disabled="loading">mdi-close-circle</v-icon>
          </div>
          <v-row class="ma-0 pa-0">
            
            <v-col cols="12" sm="5"> 
              <div class="text-left pa-1 pa-sm-0">
                Trimestre
              <custom-select style=" width: 150px" 
                :options="periods"  
                :disabled="loading"
                :placeholder="period"
                v-model="period" />
              </div> 
              <v-progress-circular v-if="loading" indeterminate color="primary" />
              <PieChart  v-else :chartdata="pieData" style="height: 230px;" />  
            </v-col>
            <v-col cols="12" sm="7"> 
          <v-simple-table dense>
            <template v-slot:default>
              <thead class="table-heading " >
                <tr>
                  <th class="text-center" style=" color:#00B99D;">Facturación<br>bruta</th>
                  <th class="text-center text-dark ">
                    <span  style="color:#FF9F00">
                      %&nbsp;de<br>participación
                    </span>
                  </th>
                  <th class="text-center text-dark ">
                  <span  style="color:#746EE5">
                    División
                  </span>
                  </th>
                </tr>
              </thead>
              <tbody class="custom-table-bordered"  v-if="!loading">
                <tr v-for="(item,index) in billingByArea" :key="index" :class="index%2==0?'cell-bg-gray':''">
                  <td >{{ formatNumber(item?.NETWR1,"$",0) }}</td>
                  <td >{{  formatNumber(item?.PORCE,"",2) }}%</td>
                  <td >{{ item.DIVI}}</td>
                </tr>
                <tr v-if="total > 0">
                  <td colspan="2" > {{ formatNumber(total,"$",0) }}</td>
                  <td>Total</td>
                </tr>
                <tr v-if="!billingByArea?.length">
                <td colspan="3">
                  <span class="text--disabled"> No se encontraron datos {{ billingByArea?.lenght }}</span>
                </td>
                </tr> 
              </tbody>
              <tbody v-if="loading" >
                <tr>
                <td colspan="3">
                  <v-progress-linear class="mt-3" indeterminate color="teal"/> 
                  <span class="text--disabled"> Cargando información</span>
                </td>
                </tr> 
              </tbody>
            </template>
          </v-simple-table>
            </v-col>
          </v-row> 
          <v-btn class="ma-2" @click="showDialog = false" :disabled="loading">Cerrar</v-btn>
    </v-card>
        </v-dialog>
      
    </div>
</template>
<script>

import PieChart from "@/components/PieChart.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import mixinService from  '@/mixins/service.js'; 
import helpers from "@/mixins/helpers";
import numbersFormats from "@/mixins/numbersFormats";
import axios from "@/plugins/axios.js";

export default({
    name:"BillingByArea",
    mixins:[mixinService,helpers,numbersFormats],
    components:{
      PieChart,
      CustomSelect},
    props:{ 
      defaultPeriod:{  
          default(){
            let currentMonth =  new Date().getMonth()+1; 
            let trim = Math.ceil(currentMonth/3);
            let currentYear = new Date().getFullYear();
            return (trim+"-"+currentYear)  
          }
        }, 
        title: {
          type: String,
          default:  ''
        }, 
        value: { 
            type: Boolean,
            default: false
          },
        url: {
          type: String,
          default:"/seller/home/sales-by-div"
        },
        employeeNumber:{ 
        },
        employeeChannel:{ 
          type: String
        }
        },  
    data: () => ({ 
        period:"",
        periods: [],
        pieData:{}, 
        total:0, 
        loading:true,
        billingByArea:[],
    }),  
    mounted() {
      this.ini();  
    },
    methods:{ 
      defaultTrim(){
          let currentMonth =  new Date().getMonth()+1;
          let trim = Math.ceil(currentMonth/3);
          let currentYear = new Date().getFullYear();
          return (currentYear+"-"+trim)  
        },
        ini(){ 
          this.periods = this.getQuartersList(11);
          this.period =   this.periods[0].value;  
        },
        loadSalesPerArea(per){
          let year = per.slice(0,4);
          let trim = per.slice(5,6);  
          this.loading =true;
          axios
              .post(this.url, {
                seller_id: this.employeeNumber,
                trim: trim,
                year: year,
                channel: this.employeeChannel
              })
              .then((response) => {
                if (response.data?.success==false) {
                    console.log( response.data)
                } else {
                    if (!response.data.DIVISION) {
                      this.billingByArea = []; 
                    }else {
                      this.billingByArea = response.data.DIVISION; 
                    }
                    
                } 
                this.getDataChart();
                this.loading =false; 
              })
              .catch((e) => {
                console.log(e);
                this.loading =false; 
              }); 
        },
        getDataChart(){   
        this.pieData={};
        const pluck = (arr,key) => arr.map(i => parseFloat(i[key]).toFixed(2));
        const labels = (arr,key) => arr.map(i => i[key]); 
      
        const percent = pluck(this.billingByArea, 'PORCE'); 
        this.total = this.arraySum (pluck(this.billingByArea, 'NETWR1'));
        const labl = labels(this.billingByArea, 'DIVI');   
        const colors = ['#FF9F00','#00B99D','#746EE5','#CD6155','#AF7AC5','#1F618D','#F5B041','#0B5345','#CA6F1E','#7D6608'];
        this.pieData= {   
            datasets: [
                      {
                        backgroundColor:colors,
                        data: percent,
                      
                      }
                    ],
            labels:labl
                }
         // this.loading=false
        }, 
  },
  computed: {
    showDialog: { 
        set(value) { 
          this.$emit('input', value);
        },
        get(){
          return this.value
        }
      }
  },
  watch:{
    showDialog(value){
      if (value) this.loadSalesPerArea(this.period)
      },
    period(value){
      if (value && this.employeeChannel && this.employeeNumber) {
        this.loadSalesPerArea(value)
      }
    }
  }
})
</script>
