<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
        icon 
        v-bind="attrs" 
        v-on="on" 
        @click="launchDataStep" 
        :class="{ 'mr-5': isLarge }"
        :height="isLarge ? 45 : undefined" 
        :width="isLarge ? 45 : undefined"
        :loading="loading" 
        :disabled="loading" 
      >
        <v-icon :size="isLarge ? 45 : undefined" color="black" v-if="!loading">
          mdi-account-edit
        </v-icon>
      </v-btn>
    </template>
    <span>Actualización de datos de contacto</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DataUpdateStepLauncher',

  props: {
    isLarge: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false, 
    };
  },

  methods: {
    ...mapActions('modalStepsAfterLogin', [
      'updateModalState',
      'findStepByKey',
      'changeCurrentStepValue',
      'updateStepStatus',
      'loadUpdateDataStep' // Nueva acción para cargar solo el paso de actualización
    ]),
    ...mapActions("updateData", ["setMustUpdateData"]), 
    
    async launchDataStep () {
      this.loading = true; 
      try {
        this.setMustUpdateData(true); 
        await this.loadUpdateDataStep();

        const updateDataStep = await this.findStepByKey('updateData');

        if (updateDataStep) {
          updateDataStep.visited = true;

          if (updateDataStep.doneRequired === true) {
            updateDataStep.doneRequired = false;
          }

          this.updateStepStatus(updateDataStep);
          this.changeCurrentStepValue(updateDataStep.value);
          this.updateModalState(true);
        }
      } finally {
        this.loading = false; 
      }
    }
  }
};
</script>
