<template>
  <v-container fluid class="white pb-10 pt-7 px-5">
    <div class="d-flex align-center gap-2">
      <back-btn />
      <div class="outlined-card pa-4">
        <icon-title text="Orden combo" icon="mdi-bookmark-check"/>
      </div>
    </div>

    <blocked-by-backorders-alert v-if="blockViewByBackordersPending" class="mt-5" />

    <div v-if="!orderCreated">
      <customer-order-information 
        :customer-data="customerData"
        :customer-channel="customerData?.CUSTOMER?.channel || ''"
        :past-due-balance="walletPastDueBalanceFormated"
        :current-order-status="orderStatus"
        :current-order-total="cartTotals.total"
        :available-credit="availableCreditFormated"
        :loading="loadingCustomerData"
        :error="customerDataError || loadCustomerError"
      />

      <v-card class="pa-5 my-5 card-shadow" rounded="0" v-if="isACreditCustomer">
        <tax-data-form 
          v-model="orderForm"
          :payment-methods="paymentValues.paymentMethod"
          :ways-to-pay="paymentValues.wayToPay"
          :cfdi-uses="paymentValues.cfdiUse"
        />
      </v-card>

      <v-card class="pa-5 my-5 card-shadow" rounded="0">
        <order-information-form 
          v-model="orderForm"
          :cfdi-relations="paymentValues.cfdiRelac"
          :address-list="shippingAddressList"
          cols-md="6"
        />
      </v-card>

      <v-row class="my-5">
        <div class="mt-4" v-if="materialErrorMessage.length > 0">
          <v-alert text color="warning"> {{ materialErrorMessage }}</v-alert>
        </div>
        <v-col cols="12" md="8" order="2" order-md="1">
          <v-card class="pa-5  card-shadow">
            <add-material-form
              @onAddMaterial="getMaterialData"
              title="Buscar"
              button-text="Agregar"
              no-selectable-material
              material-input-class="col-12 col-md-8"
              qty-input-class="col-12 col-md-4"
              button-input-class="col-12 col-md-4 offset-sm-0 offset-md-8"
              :disabled="loadingMaterial || blockViewByBackordersPending"
              :loading="loadingMaterial"
              :material-length="6"
              button-color="red"
            />
          </v-card>
        </v-col>
        <v-col order="1" order-md="2">
          <v-alert color="success" class="card-shadow pst-0" dense>
            <div class="d-flex justify-content-center">
              <switch-date-picker 
                v-model="orderForm.deliverOrderDate" 
                label="Crear pedido programado"
                selected-date-prefix="Programado para el día"
                :min-date="todayPlusOne"
                :disabled="loadBackordersFromStore"
              />
            </div>
          </v-alert>
        </v-col>
      </v-row>

      <div v-if="cart.length > 0" class="mt-10">
        <order-cart-table
          title="Carrito de combos"
          :cart-items="cartModified"
          :hide-headers="hideHeaders"
          :total="cartTotals.total"
          :subtotal="cartTotals.subtotal"
          :iva="cartTotals.iva"
          editable-units
          has-childs
          @onDeleteItem="(item, index) => removeFromCart(index)"
          @updateUnitsItem="updateCartItemQty"
        />
       
        <!-- Alerts -->
        <div class="mt-10">
          <v-alert text color="error" v-if="cartMinimumTotalMessage.length > 0"> {{ cartMinimumTotalMessage }}</v-alert>
          <v-alert text color="primary" v-if="creditLimitExceededMessage.length > 0"> {{ creditLimitExceededMessage }}</v-alert>
          <v-alert text color="error" v-if="orderWillBeBlockedMessage.length > 0"> {{ orderWillBeBlockedMessage }}</v-alert>
          <v-alert text color="error" v-if="invalidOrderDataMessage.length > 0"> {{ invalidOrderDataMessage }}</v-alert>
        </div>

        <v-row class="mt-10" justify="end">
          <div class="button-container">
            <v-btn @click="storeOrder" :disabled="!cartHasMinimumTotal" :dark="cartHasMinimumTotal" class="card-shadow" color="#007D36">
              Confirmar pedido <v-icon color="white">mdi-checkbox-multiple-marked-circle</v-icon>
            </v-btn>
          </div>
        </v-row>
      </div>
    </div>
    
    <!-- Created order success message -->
    <div v-else>
      <v-card class="pa-5 my-10 card-shadow">
        <icon-title v-if="orderWillBeBlockedMessage.length > 0" :text="orderWillBeBlockedMessage" class="mb-5 text-left" />
        <icon-title :text="orderCreationResponse.message" />

        <div class="text-left">Número de pedido: {{ orderCreationResponse.orderNumber }}</div>
        <div v-if="validOrderCreationNumber(orderCreationResponse.shippingNumber)" class="text-left mt-2">Número de entrega: {{ orderCreationResponse.shippingNumber }}</div>
        <div v-if="validOrderCreationNumber(orderCreationResponse.billNumber)" class="text-left mt-2">Número de factura: {{ orderCreationResponse.billNumber }}</div>
        <v-row class="mt-15">
          <v-col cols="6">
            <v-btn block color="success" to="/">Ir a inicio</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="primary" to="/history-orders">Ir a historial</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <overlay :show="updatingMaterial" text="Validando información del combo..."/>
    <overlay :show="savingOrder" text="Generando pedido..."/>
    <overlay :show="loadingReminders" text="Validando los backorders seleccionados..."/>
  </v-container>
</template>

<script>
  import TaxDataForm from '@/components/forms/TaxDataForm.vue';
  import OrderInformationForm from '@/components/forms/OrderInfoForm.vue';
  import AddMaterialForm from '@/components/forms/AddMaterialForm';
  import OrderCartTable from '@/components/tables/CartOrderTable';
  import CustomerOrderInformation from '@/components/CustomerOrderInformation.vue';
  import IconTitle from '@/components/IconTitle.vue';
  import Overlay from '@/components/Overlay.vue'
  import SwitchDatePicker from '@/components/SwitchDatePicker';
  import BackBtn from '@/components/BackBtn.vue';
  import BlockedByBackordersAlert from '@/components/BlockedByBackordersAlert'

  import numbersFormats from '@/mixins/numbersFormats';
  import userBlockedByBackorders from '@/mixins/userBlockedByBackorders';

  import api from '@/plugins/axios';
  import { generatePurchaseOrderId } from '@/repositories/Orders';

  import { mapGetters, mapActions } from 'vuex';

  const finantialErrorsCountInit = { commercial: 0, manager: 0, gpom4: 0 }

  export default {
    name: 'CreateKitOrder',

    components: { 
      IconTitle, 
      TaxDataForm, 
      OrderInformationForm,
      AddMaterialForm,
      OrderCartTable,
      CustomerOrderInformation, 
      Overlay,
      SwitchDatePicker,
      BackBtn,
      BlockedByBackordersAlert
    },

    mixins: [numbersFormats, userBlockedByBackorders],

    data(){
      return {
        cart: [],

        loadingMaterial: false,
        updatingMaterial: false,

        customerData: null,
        customerCreditData: null,
        loadingCustomerData: false,
        customerDataError: false,
        materialErrorMessage: '',

        paymentValues: {
          cfdiRelac: [],
          paymentMethod: [],
          wayToPay: [],
          cfdiUse: [],
        },

        orderForm: {
          orderConcept: '',
          shippingAddressId: '',
          paymentMethod: '',
          wayToPay: '',
          cfdiUse: '',
          documents: '',
          reBill: '',
          deliverOrderDate: null
        },

        shippingAddressList: [],
        invalidOrderDataMessage: '',

        finantialErrorsCount: { ...finantialErrorsCountInit },

        orderWillBeBlockedMessage: '',
        blockStatus: 1,
        orderCreated: false,
        savingOrder: false,
        orderCreationResponse: null,

        loadingReminders: false,

        todayPlusOne: null,
      }
    },

    async created(){
      const today = new Date();
      this.todayPlusOne = today.setDate(today.getDate() + 1)

      this.loadingCustomerData = true;

      this.validateLoadBackorders(); // If user comming from reminders history, it sets reminders selected owner code.
      this.orderForm.orderConcept = await generatePurchaseOrderId(this.loadBackordersFromStore ? 'backorder' : 'normal');

      await this.loadCustomer(); // Loads user data if the user is a seller it will load data of a preselected customer
      
      if (this.noCustomerSelected) // If seller is signed and no preselected customer, we redirect to home
        this.$router.replace('/');
      else if(!this.loadCustomerError) { // If a error happens while loading preselected customer
        this.validateUserBlockedByBackorders();
        await this.fetchCustomerInfo();
        this.loadFromState(); // When user is redirected from the reminders history view. It loads the materials selcted.
      }

      this.sendToPDF({ active: true });
      this.loadingCustomerData = false;
    },

    methods: {
      ...mapActions('selectedUser', ['loadCustomer', 'setSelectedCustomerCode']),
      ...mapActions('printer', ['sendToPDF']),
      ...mapActions('reminders', ['updateRemindersSelectionList', 'loadReminders']),
      ...mapActions('modalStepsAfterLogin', ['syncSteps']),

      async fetchCustomerInfo(){
        try {
          const customerInfo = api.post('/get-customer-info', this.userData);
          const crediData = api.post('/get-customer-credit-data', this.userData);
          const addresses = api.post('/destinyCustomer', this.userData);

          const [{ data }, { data: creditData }, { data: customerDestinyList }] = await Promise.all([customerInfo, crediData, addresses]);
          
          this.customerData = data.customerData;
          this.paymentValues.cfdiRelac = data.cfdiRelac;
          this.paymentValues.paymentMethod = data.paymentMethods;
          this.paymentValues.wayToPay = data.waysToPay;
          this.paymentValues.cfdiUse = data.cfdiUses;
          this.customerCreditData = creditData;
          this.shippingAddressList = customerDestinyList;
        } catch (error) {
          this.customerDataError = true;
        }
      },

      async getMaterialData(form) {
        this.loadingMaterial = true;
        this.materialErrorMessage = '';

        const isNotInCart = this.validateMaterialNotInCart(form.materialId);

        if(isNotInCart){
          const material = await this.fetchMaterial(form.materialId, form.requiredQty);
          
          if (material)
            this.cart.push(material);
        } else {
          this.materialErrorMessage = 'Este material ya está dentro del pedido';
        }

        form.materialId = '';
        form.requiredQty = null;
        this.loadingMaterial = false;
      },

      async fetchMaterial(materialId, qty) {
        const requiredQty = parseInt(qty).toString();
        this.materialErrorMessage = '';
        
        try {
          const response = await api.post('/kit/get-material', { ...this.customer, materialId, requiredQty });
          return response.data
        } catch (error) {
          this.materialErrorMessage = this.resolveHttpError(error, [400, 404], 'Sucedió un error al recuperar la información del combo');
        }

        return null;
      },

      async updateCartItemQty(item, index) {
        this.updatingMaterial = true;
        const material = await this.fetchMaterial(item.materialId, item.unitsRequired);

        if (material)
          this.cart.splice(index, 1, material)
        else 
          this.cart.splice(index, 1);

        this.updatingMaterial = false;
      },

      removeFromCart(index) {
        if(this.loadBackordersFromStore && this.cart[index].isBackorder && this.countBackordersInCart() == 1){
          this.materialErrorMessage = 'Debes tener al menos un backorder en tu carrito';
        }
        else {
          this.cart.splice(index, 1);
        }
      },

      validateMaterialNotInCart(materialId){
        const shortMaterialId = materialId.slice(-6);

        const index = this.cart.findIndex(m => m.materialId == shortMaterialId && !m.isBackorder );
        return index < 0;
      },

      async storeOrder() {
        this.invalidOrderDataMessage = '';
        const dataValidMessage = this.validateOrderData();
        const orderConceptGpom4 = this.finantialErrorsCount.gpom4 >= 1 ? '-BC' : '';

        if (this.savingOrder) {
          return
        }

        if (dataValidMessage != null) {
          this.invalidOrderDataMessage = dataValidMessage;
        } else {
          const form = {
            ...this.userData,
            ...this.orderForm,
            orderConcept: `${this.orderForm.orderConcept}${orderConceptGpom4}`,
            cart: this.cart,
            blockStatus: this.blockStatus,
            createdBySeller: !this.loggedinUserIsCustomer,
            sellerCode: this.userCode,
          }

          try {
            this.savingOrder = true;
            const storeReelResponse = await api.post('/kit/store-order', form);
            this.orderCreationResponse = storeReelResponse.data;
            this.orderCreated = true;

            this.updateBackorders(form.deliverOrderDate !== null);
            
            this.goToHistory();
          } catch ({ response }) {
            this.invalidOrderDataMessage = this.resolveHttpError(response, [400], 'No se pudo crear el pedido');
          }

          this.savingOrder = false;
        }

      },

      validateOrderData() {
        const invalidOrderCharacter = /[|]|[~]|[¬]|[']/.test(this.orderForm.orderConcept);

        if (invalidOrderCharacter) {
          return 'Carácter inválido en orden de compra';
        } else if (this.orderForm.orderConcept == '' && this.orderForm.shippingAddressId == '') {
          return 'Favor de completar orden de compra y destino';
        } else if (this.orderForm.orderConcept == '') {
          return 'Favor de completar orden de compra';
        } else if (this.orderForm.shippingAddressId == '') {
          return 'Favor de completar destino';
        } else if (this.isACreditCustomer && this.orderForm.paymentMethod == '') {
          return 'Favor de completar metodo de pago';
        } else if (this.isACreditCustomer && this.orderForm.wayToPay == '') {
          return 'Favor de completar via de pago';
        } else if (this.isACreditCustomer && this.orderForm.cfdiUse == '') {
          return 'Favor de completar uso de CFDI';
        }

        return null;
      },

      checkOrderBlocks(){
        this.finantialErrorsCount = { ...finantialErrorsCountInit };
        this.orderWillBeBlockedMessage = '';

        this.cart.forEach(({ finantialErrors }) => {
          this.finantialErrorsCount.commercial += finantialErrors.commertial ? 1 : 0;
          this.finantialErrorsCount.manager += finantialErrors.manager ? 1 : 0;
          this.finantialErrorsCount.gpom4 += finantialErrors.gpom4 ? 1 : 0;
        })
        
        const { commercial, manager } = this.finantialErrorsCount;
        
        this.blockStatus = 1;

        if(this.noCreditProblems){
          if(commercial != 0 && manager == 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización gerencia de planeación.';
            this.blockStatus = 3;
          } else if (commercial == 0 && manager != 0) {
            this.orderWillBeBlockedMessage  = 'El pedido será bloqueado para autorización de la gerencia.';
            this.blockStatus = 5;
          } else if (commercial != 0 && manager != 0) {
            this.blockStatus = 7;
            this.orderWillBeBlockedMessage  = 'El pedido será bloqueado para autorización de la gerencia y comercial.';
          }
        } else {
          if (manager == 0 && commercial == 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado por crédito';
            this.blockStatus = 2;
          } else if (commercial != 0 && manager == 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización comercial y crédito.';
            this.blockStatus = 4;
          } else if (commercial == 0 && manager != 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización de la gerencia y crédito.';
            this.blockStatus = 6;
          } else if (commercial != 0 && manager != 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización de la gerencia, comercial y crédito.';
            this.blockStatus = 8;
          }
        }
      },

      async loadFromState(){
        if(this.loadBackordersFromStore && this.firstReminderSelectedIsKit) {
          this.loadingReminders = true;

          for(let i = 0; i < this.selectedReminders.length; i++){
            const { materialId, requiredQty, id } = this.selectedReminders[i];

            const material = await this.fetchMaterial(materialId, requiredQty);

            if(material) {
              material.noEditableUnits = true,
              material.isBackorder = true,
              material.backorderId = id,

              this.cart.push(material);
            }
          }

          this.loadingReminders = false;
        }
      },
              
      validateLoadBackorders() {
        if (this.loadBackordersFromStore) {

          if (!this.firstReminderSelectedIsKit) {
            this.$router.replace('/');
            return;
          } 
      
          if (!this.loggedinUserIsCustomer) {
            const customer = this.firstReminderSelected.customer.code;
            this.setSelectedCustomerCode(customer);
          }
        }
      },

      updateBackorders(reminderCreated) {
        if (reminderCreated || this.loadBackordersFromStore) {
          this.updateRemindersSelectionList([]);
          this.loadReminders();
          this.syncSteps();
        }
      },

      countBackordersInCart() {
        const numberOfBackorders = this.cart.reduce((count, item) => {
          return count += item.isBackorder ? 1 : 0;
        }, 0)

        return numberOfBackorders;
      },

      validOrderCreationNumber(number){
        return number.length > 0 && number !== '0000000000'
      },

      resolveHttpError(error, statusCodes = [], defaultMessage = ''){
        const { response } = error;

        if(response && statusCodes.includes(response.status))
          return response.data.message;

        return defaultMessage;
      },

      goToHistory(){
        setTimeout(() => {
          this.$router.push('/history-orders')
        }, 5000);
      },
    },

    computed: {
      ...mapGetters('auth', ['channel', 'userCode', 'user']),
      ...mapGetters('selectedUser', ['customer', 'loadCustomerError', 'noCustomerSelected', 'loggedinUserIsCustomer']),

      ...mapGetters('reminders', [
        'selectedReminders',
        'firstReminderSelected',
        'firstReminderSelectedIsKit',
        'blockedUser'
      ]),

      cartModified() {
        return this.cart.map(item => {
          return {
            ...item,
            listPrice: '0',
            productPrice: '0',
            discountPrice: '0',
            childs: item.childs.map(child => ({ ...child, stock: child.stockCdpt, stockCdpt: '0' }))
          }
        });
      },

      cartSubtotal() {
        return this.cart.reduce((sum, currentValue) => sum + parseFloat(currentValue.productPrice), 0);
      },

      cartTotalIva() {
        return this.cart.reduce((sum, currentValue) => sum + parseFloat(currentValue.taxesAmount), 0);
      },

      cartTotal() {
        return this.cartSubtotal + this.cartTotalIva;
      },

      cartTotals() {
        return { total : this.formatNumber(this.cartTotal), subtotal: this.formatNumber(this.cartSubtotal), iva: this.formatNumber(this.cartTotalIva)}
      },

      cartProfitMargin() { 
        return this.cart.reduce((sum, currentValue) => sum + parseFloat(currentValue.profitMargin), 0);
      },

      cartMinimunAmount(){
        return this.userData.VTWEG === 'PR' ? 5000 : 2500;
      },

      cartHasMinimumTotal() {
        if(this.loadBackordersFromStore && !this.isAHybridCart) return true; // There is no restriction when user wants to bill ONLY backorders
          return this.cartTotal >= this.cartMinimunAmount;
      },

      customerCredit(){
        const credit = this.customerData?.CREDD || '0';
        const creditNoCommas = credit.replace(/,/gi, '');

        return parseFloat(creditNoCommas);
      },

      isACreditCustomer(){
        const zterm = this.customerData?.ZTERM || '';
        return zterm == 'IU00'; 
      },

      availableCredit(){
        return this.customerCredit - this.cartTotal;
      },

      availableCreditFormated(){
        return this.formatNumber(this.availableCredit);
      },

      walletPastDueBalance(){
        return this.customerCreditData?.wallet?.T7 || null
      },

      pastDueBalanceCount(){
        return this.customerCreditData?.pastDueBalancesCount || 0;
      },

      walletPastDueBalanceFormated(){
        if(this.walletPastDueBalance === null) return '';

        return this.formatNumber(this.walletPastDueBalance);
      },

      noCreditProblems(){
        if(this.pastDueBalanceCount > 0 ||  this.customerCredit < 0 || this.availableCredit < 0){
          return false;
        }

        return true;
      },
      
      orderStatus(){
        if(this.walletPastDueBalance === null)
          return 'Análisis Crediticio 2'
        else if(this.availableCredit < 0 || this.walletPastDueBalance < 0)
          return 'Análisis Crediticio'
        else
          return 'Correcto'
      },

      // Alert Messages 
      cartMinimumTotalMessage(){
        // console.log('min', this.cartHasMinimumTotal)
        if(this.cartTotal > 0 && !this.cartHasMinimumTotal)
          return `El monto mínimo de compra es ${this.formatNumber(this.cartMinimunAmount)} MXP` // MXM ???

        return '';
      },

      creditLimitExceededMessage(){
        if(this.walletPastDueBalance > 0 || this.orderStatus != "Correcto"){
          return "Se ha excedido el límite de crédito y/o partidas vencidas. Se generará su pedido.";
        }

        return ''
      },

      // A hybrid cart is true when user is billing backorders and adds at least one material from the search form
      isAHybridCart () { 
        if(!this.loadBackordersFromStore) return false;

        const noBackorderProductIndex = this.cart.findIndex(i => i.isBackorder !== true);
        return noBackorderProductIndex >= 0; // If >= 0 it means that a material was added from the search form
      },

      hideHeaders(){
        const headers = ['reelNumber', 'stockCdpt', 'textBox'];
        if(this.loggedinUserIsCustomer) headers.push('stock');

        return headers;
      },

      userData(){
        return this.customer;
      },

      loadBackordersFromStore () { // When user is comming from backorders history view
        return this.$route.query?.backorders || false;
      },

      blockViewByBackordersPending () { // If true it has backorders with enought stock
        return this.userIsBlockedByBackorders && !this.loadBackordersFromStore;
      }
    },

    watch: {
      cart () {
        this.checkOrderBlocks();
      },

      // If user is in /combo-order?backorders=true and wants to go to /combo-order we force a refresh, otherwise it will not remount the view
      loadBackordersFromStore() { 
        this.$router.go();
      }
    }
  }
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: right;
}

.button-container > button {
  width: 400px;
  max-width: 400px;
  border-radius: 8px;
}

@media screen and (max-width: 960px) {
  .button-container > button {
    width: 100%;
    max-width: auto;
  }
}
</style>