import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import SignIn from "../views/auth/SignIn.vue";
import SignUp from "../views/auth/SignUp.vue";
import PasswordReset from "../views/auth/PasswordReset.vue";
import ConfirmAccount from "../views/auth/ConfirmAccount.vue";
import RecoverPassword from "../views/auth/RecoverPassword.vue";
import ShoppingCart from "../views/shopping_cart/ShoppingCart.vue";
import ShoppingCartReminder from "../views/shopping_cart_reminder/ShoppingCartReminder.vue";
import ShoppingCartM from "../views/shopping_cart_reminder/ShoppingCartM.vue";
import HistoryOrders from "../views/history_orders/HistoryOrders.vue";
import HistoryRecords from "../views/history_records/HistoryRecords.vue";
import CustomerRegistration from "../views/client_request/CustomerRegistration.vue";
import CustomerUpdate from "../views/client_request/CustomerUpdate.vue";
import CustomerStatus from "../views/client_request/CustomerStatus.vue";
import FillRate from "../views/fill_rate/FillRate.vue";
import OpportunityMarket from "../views/opportunity_market/OpportunityMarket.vue";
import ContactCreate from "../views/contact/ContactCreate.vue";
import ContactList from "../views/contact/ContactList.vue";
import MultimediaCenter from "../views/multimedia_center/MultimediaCenter.vue";
import MultimediaCenterVideo from "../views/multimedia_center/MultimediaCenterVideo.vue";
import UploadFile from "../views/order_csv/UploadFile.vue";
import AccountStatus from "../views/account_status/AccountStatus.vue";
import Planeador from "../views/planeador/Planeador.vue";
import PlaneadorManager from "../views/planeador/Planeador.vue";
import ProfileUser from "../views/profile_user/ProfileUser.vue";
import Forbidden from "../views/errors/403.vue";
import Manager from "../views/client_request/Manager.vue";
import ManagerRequest from "../views/client_request/ManagerRequest.vue";
import ManagerHome from "../views/manager/ManagerHome.vue";
import Panelsol from "../views/iusasol/Panelsol.vue";
import QuoterCart from "../views/quoter_cart/QuoterCart.vue";
import NewQuoterCart from "../views/quoter_cart/NewQuoterCart.vue";
import NewQuoterCartEdit from "../views/quoter_cart/NewQuoterCartEdit.vue";
import QuoterHistory from "../views/quoter_cart/QuoterHistory.vue";
import QuoterCartEdit from "../views/quoter_cart/QuoterCartEdit.vue";
import QuoterCartShopping from "../views/quoter_cart/QuoterCartShopping.vue";
import Administrator from "../views/administrator/AdministratorHome.vue";
import Renglonaje from "../views/renglonaje/Renglonaje.vue";
import RenglonajeSeller from "../views/renglonaje_seller_manager/Renglonaje.vue";
import RenglonajeManager from "../views/renglonaje_seller_manager/Renglonaje.vue";
import RenglonajeDirector from "../views/renglonaje/RenglonajeDirector.vue";
import ShoppingCartPR from "../views/shopping_cart_pr/ShoppingCart.vue";
import ShoppingCartMPR from "../views/shopping_cart_reminder_pr/ShoppingCartMPR.vue";
import ShoppingCartReminderPR from "../views/shopping_cart_reminder_pr/ShoppingCartReminderPR.vue";
import QuoterCartShoppingPR from "../views/quoter_cart/QuoterCartShoppingPr.vue";
import DiscontinuedMaterial from "../views/discontinued_material/DiscontinuedMaterial.vue";
import DiscontinuedMaterialPR from "../views/discontinued_material/DiscontinuedMaterialPR.vue";
import ShoppingCartPopPr from "../views/shopping_cart_pr/ShoppingCartPop.vue";
import ShoppingCartPsmaPr from "../views/shopping_cart_pr/ShoppingCartPsma.vue";
import ShoppingCartPdrPr from "../views/shopping_cart_pr/ShoppingCartPdr.vue";
import ShoppingCartPsnwPr from "../views/shopping_cart_pr/ShoppingCartPsnw.vue";
import ShoppingCartPstn from "../views/shopping_cart/ShoppingCartPstn.vue";

import ShoppingCartPop from "../views/shopping_cart/ShoppingCartPop.vue";
import ShoppingCartPsma from "../views/shopping_cart/ShoppingCartPsma.vue";
import ShoppingCartPdr from "../views/shopping_cart/ShoppingCartPdr.vue";
import ShoppingCartPsnw from "../views/shopping_cart/ShoppingCartPsnw.vue";


//logistic reports
import logistics_reports from "../views/logistics_reports/index.vue";



//
import ClientGroup from "../views/client_group/ClientGroup.vue";
import store from "@/store";
import refundsRoutes from "./refundsRoutes";
import administratorRoutes from "./administratorRoutes";
import CustomerAccountStatement from '../views/customer_account_statement/Account_Statement'
import sellerRoutes from './sellerRoutes';
import directorRoutes from './directorRoutes';
import creditRoutes from './credit';
import ReportStock from "../views/report_stock/ReportStock.vue";

import Briefcase from "../views/briefcase/Briefcase.vue";
import OrderStatus from "../views/order_status/OrderStatus.vue";
import OrderDetail from "../views/order_status/Detail.vue";
import PromissoryNotes from "@/views/client_request/promissory_notes/PromissoryNotes.vue";

import DayCustomerDelivery from "../views/day-customer-delivery/DayCustomerDelivery.vue";
import DayCustomerDeliveryDirector from "../views/day-customer-delivery/DayCustomerDeliveryDirector.vue";

import InvoicingManager from "../views/invoicing/invoicing_manager.vue"
import InvoicingDirector from "../views/invoicing/invoicing_director.vue";

import StructureHome from "@/views/structure_director_regional/StructureHome.vue";

import NewShoppingCart from "../views/shopping_cart/NewShoppingCart.vue";
/** Test component  */
import testTimming from "../views/metrics/timming.vue";
import FrontMetrics from "../views/metrics/FrontMetrics.vue";
import SoapMetrics from "../views/metrics/SoapMetrics.vue";
/** Test component  */

 

import OCPendientes from "../views/order_merc/OCPendientes.vue";
import Vinculos from "../views/vinculos/Vinculos.vue";

import NewShoppingCartPR from "../views/shopping_cart/NewShoppingCartPR.vue";
import NewShoppingCartBackPR from "../views/shopping_cart/NewShoppingCartBackPR.vue";
import NewShoppingCartBack from "../views/shopping_cart/NewShoppingCartBack.vue";

/** Test component  */
import DirectorChannelSelection from "../views/auth/DirectorChannelSelection.vue";

import QuotationStatistics from "../views/statistics/QuotationStatistics.vue";
import CreateReelOrder from "../views/reel_order/CreateReelOrder.vue";
import CreateReelReminderOrder from "../views/reel_order/CreateReelReminderOrder.vue";
import CreateKitOrder from '../views/kit_order/CreateKitOrder.vue';

import ConsultasDT from "@/views/vinculos/ConsultasDT.vue";
import Notifications from "@/views/notifications/Notifications.vue";
import ResultsExpressSales from "../views/expressSales/ResultsExpressSales.vue";
import GeneralGroupDataCounters from "../views/general_group_data_counters/GeneralGroupDataCounters.vue";

Vue.use(VueRouter);

export const hasAnyRole = (storeroles, roles) => {
    return storeroles.some(role => roles.includes(role.name));
};

export const hasAnyPermission = (storepermissions, permissions) => {
  return storepermissions.some(permission => permissions.includes(permission.name));
};

const routes = [
  
  {
    path: "/",
    name: "SignIn",
    component: SignIn,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "Home",
        });
      }
      next();
    },
  },
  {
    path: "/home",
    name: "Home",
    component: HomeView,
    meta: {role: 'Customer', redirectpermissions: ["pgc.redirect"]},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Seller"])) {
        return next({ name: "SellerResults" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Manager",'Submanager','IELManager','IELSubManager'])) {
        return next({ name: "ManagerHome" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Director", "IELDirector", "IELCommercialDT", "IELCommercialPR", 'Subdirector'])) {
        return next({ name: "DailyDirector" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ['RegionalManager', 'IELRegManager'])) {
        return next({ name: "DailyRegionalManager" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Administrator"])) {
        return next({ name: "Administrator" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["IELLogistics"])) {
        return next({ name: "logistics_reports" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Credit"])) {
        return next({ name: "CreditIndex" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["SellerPGC"])) {
        return next({ name: "RequestsSeller" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["ManagerPGC"])) {
        return next({ name: "Manager" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["IELExpressSales"])) {
        return next({ name: "DirectorChannelSelection" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["NotificationAdmin"])) {
        return next({ name: "Notifications" });
      }
      next();
    },
  },
  {
    //Caratula cliente desde el rol gerente y vendedor
    path: "/customer-cover",
    name: "CustomerCover",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ["Manager", "Seller", 'Submanager', 'IELManager', 'IELSubManager'])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/customer-update",
    name: "CustomerUpdate",
    component: CustomerUpdate,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/client-group/:opt?",
    name: "ClientGroup",
    component: ClientGroup,
    meta: {role: 'Customer'},
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/cartera",
    name: "Briefcase",    
    component: Briefcase,
    meta: {role: 'Seller'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/order-status/:opt?",
    name: "OrderStatus",
    component: OrderStatus,
    meta: {role: 'Customer'},
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/order-detail/:id",
    name: "OrderDetail",
    component: OrderDetail,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/client-request",
    name: "CustomerRegistration",
    component: CustomerRegistration,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (
        store.getters["auth/user"].request_status == "Aceptada" ||
        store.getters["auth/user"].request_status == "Rechazada"
      ) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/customer-status",
    name: "CustomerStatus",
    component: CustomerStatus,
    beforeEnter: (to, from, next) => {
      console.log(to);
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      next();
    },
  },
  {
    path: "/account-status/:h?",
    name: "AccountStatus",
    component: AccountStatus,
    meta: {role: 'Customer'},
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Manager", 'Submanager', 'IELManager', 'IELSubManager'])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-remider",
    name: "ShoppingCartReminder",
    component: ShoppingCartReminder,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-m",
    name: "ShoppingCartM",
    component: ShoppingCartM,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart",
    name: "ShoppingCart",
    component: ShoppingCart,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  /*New shopping cart*/
  {
    path: "/shoppingcart/crear/:doctype/:idq",
    name: "NewShoppingCart",
    component: NewShoppingCart,
    meta: {
      role: 'Customer',
      reload: true,
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
    
  },
  /*New shopping cart*/
  {
    path: "/history-orders",
    name: "HistoryOrders",
    component: HistoryOrders,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/backorders-history",
    name: "backordersHistory",
    component: () => import('../views/reminders/History'),
    meta: { role: 'Customer', validateRole: true, roles: ['Customer', 'CustomerPR'] },
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest",'Director','IELDirector'])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/history-records",
    name: "HistoryRecords",
    component: HistoryRecords,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/quoter-history", //navBar usa esta url (actualizar ambas en caso dé)
    name: "QuoterHistory",
    component: QuoterHistory,
    meta: {role: 'Customer'},
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/iusasol/panel",
    name: "Panelsol",
    component: Panelsol,
    meta: {role: 'Seller'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/quoter-cart",
    name: "QuoterCart",
    component: QuoterCart,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/cotizacion/editar/:quoter_id/:document_type?",
    meta: {role: 'Customer'},
    name: "NewQuoterCartEdit",
    component: NewQuoterCartEdit,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/cotizacion/crear",
    name: "NewQuoterCart",
    meta: {role: 'Customer'},
    component: NewQuoterCart,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/quoter-cart-edit",
    name: "QuoterCartEdit",
    component: QuoterCartEdit,
    meta: {
      role: 'Customer',
      reload: true,
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/quoter-cart-shopping",
    name: "QuoterCartShopping",
    component: QuoterCartShopping,
    meta: {
      role: 'Customer',
      reload: true,
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/profile_user",
    name: "ProfileUser",
    component: ProfileUser,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      next();
    },
  },
  {
    path: "/fill-rate",
    name: "FillRate",
    component: FillRate,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/oportunidad-mercado",
    name: "OpportunityMarket",
    component: OpportunityMarket,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/contact",
    name: "ContactList",
    component: ContactList,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/contact-create",
    name: "ContactCreate",
    component: ContactCreate,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/multimedia-center",
    name: "MultimediaCenter",
    component: MultimediaCenter,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      next();
    },
  },
  {
    path: "/multimedia-video",
    name: "MultimediaCenterVideo",
    component: MultimediaCenterVideo,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      next();
    },
  },
  {
    path: "/order-csv",
    name: "upload-file",
    component: UploadFile,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      next();
    },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "Home",
        });
      }
      next();
    },
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordReset,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "Home",
        });
      }
      next();
    },
  },
  {
    path: "/confirm-account",
    name: "ConfirmAccount",
    component: ConfirmAccount,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "Home",
        });
      }
      next();
    },
  },
  {
    path: "/recover-password/",
    name: "RecoverPassword",
    component: RecoverPassword,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "Home",
        });
      }
      next();
    },
  },
  {
    path: "/planeador",
    name: "Planeador",
    component: Planeador,
    meta: {role: 'Seller'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ['Customer', 'CustomerPR'])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  }, 
  {
    path: "/planeador-manager",
    name: "PlaneadorManager",
    component: PlaneadorManager,
    meta: {role: 'Manager'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ['Customer', 'CustomerPR', 'Seller'])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },  
  {
    path: "/manager/requests/",
    name: "Manager",
    component: Manager,
    meta: {role: 'Manager'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/administrator",
    name: "Administrator",
    component: Administrator,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ["Administrator"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/manager/requests/:id",
    name: "ManagerRequest",
    component: ManagerRequest,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    //Se cambia a seller 
    path: "/manager/home",
    name: "ManagerHome",
    component: ManagerHome,
    meta: {role: 'Manager'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Customer","CustomerPR",'Seller'])) {
        return next({ name: "403" });
      }
      next();
    },
  },
  {
    path: "/renglonaje/:view/:trim/:year",
    name: "Renglonaje",
    component: Renglonaje,
    meta: {role: 'Customer'},
    props: true,
    beforeEnter: (to, from, next) => {
      if (hasAnyRole(store.getters["auth/roles"], ['Guest'])) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/renglonaje-seller",
    name: "RenglonajeSeller",
    component: RenglonajeSeller,
    meta: {role: 'Seller'},
    props: true,
    beforeEnter: (to, from, next) => {
      if (!hasAnyRole(store.getters["auth/roles"], ["Seller"])) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/renglonaje-manager",
    name: "RenglonajeManager",
    component: RenglonajeManager,
    props: true,
    meta: {role: 'Manager'},
    beforeEnter: (to, from, next) => {
      if (!hasAnyRole(store.getters["auth/roles"], ["Manager", 'Submanager', 'IELManager', 'IELSubManager'])) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/renglonaje-director",
    name: "RenglonajeDirector",
    component: RenglonajeDirector,
    meta: {role: 'Director'},
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if(!store.getters["auth/authenticatedDirector"]){
        return next({
          name: "DirectorChannelSelection",
        });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ["Director", "IELDirector", "IELCommercialPR", "IELCommercialDT", 'Subdirector', 'RegionalManager', 'IELRegManager'])) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-pr",
    name: "ShoppingCartPR",
    component: ShoppingCartPR,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-m-pr",
    name: "ShoppingCartMPR",
    component: ShoppingCartMPR,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-remider-pr/:order",
    name: "ShoppingCartReminderPR",
    component: ShoppingCartReminderPR,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/quoter-cart-shopping-pr",
    name: "QuoterCartShoppingPR",
    component: QuoterCartShoppingPR,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/dis-material-pr",
    name: "DiscontinuedMaterialPR",
    component: DiscontinuedMaterialPR,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/dis-material",
    name: "DiscontinuedMaterial",
    component: DiscontinuedMaterial,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-pop",
    name: "ShoppingCartPop",
    component: ShoppingCartPop,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-psma",
    name: "ShoppingCartPsma",
    component: ShoppingCartPsma,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-pdr",
    name: "ShoppingCartPdr",
    component: ShoppingCartPdr,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-psnw",
    name: "ShoppingCartPsnw",
    component: ShoppingCartPsnw,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-pstn",
    name: "ShoppingCartPstn",
    component: ShoppingCartPstn,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-pop-pr",
    name: "ShoppingCartPopPr",
    component: ShoppingCartPopPr,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-psma-pr",
    name: "ShoppingCartPsmaPr",
    component: ShoppingCartPsmaPr,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },  
  {
    path: "/shopping-cart-pdr-pr",
    name: "ShoppingCartPdrPr",
    component: ShoppingCartPdrPr,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/report-stock",
    name: "ReportStock",
    component: ReportStock,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-psnw-pr",
    name: "ShoppingCartPsnwPr",
    component: ShoppingCartPsnwPr,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/shopping-cart-pstn",
    name: "ShoppingCartPstn",
    component: ShoppingCartPstn,
    meta: {role: 'Customer'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/customer-account-statement/:opt?",
    name: "CustomerAccountStatement",
    component: CustomerAccountStatement,
    meta: {role: 'Customer'},
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }/*
      if (hasAnyRole(store.getters["auth/roles"], ["Seller","Manager"])) {
        if (!store.getters['auth/customerData'] ) {
          return next({
            name: "Home",
          }); 
        }
      } */
      next();
    }
  },
  {
    path: "/pagares",
    name: "PromissoryNotes",
    component: PromissoryNotes,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
    {
    path: "/day-customer-delivery",
    name: "DayCustomerDelivery",
    component: DayCustomerDelivery,
    meta: {role: 'Manager'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ["Manager", 'Submanager', 'IELManager', 'IELSubManager'])) {
        return next({ name: "SignIn" });
      }
      next();
    }
  },
  {
    path: "/day-customer-delivery-director",
    name: "DayCustomerDeliveryDirector",
    component: DayCustomerDeliveryDirector,
    meta: {role: 'Director'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if(!store.getters["auth/authenticatedDirector"]){
        return next({
          name: "DirectorChannelSelection",
        });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['Director', 'IELDirector', 'IELCommercialPR', 'IELCommercialDT', 'Subdirector'])) {
        return next({ name: "SignIn" });
      }
      next();
    }
  },
  {
    path: "/day-customer-delivery-regional",
    name: "DayCustomerDeliveryRegional",
    component: DayCustomerDeliveryDirector,
    meta: {role: 'Regional'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['Director', 'IELDirector', 'IELCommercialPR', 'IELCommercialDT', 'Subdirector', 'RegionalManager', 'IELRegManager'])) {
        return next({ name: "SignIn" });
      }
      next();
    }
  },
  {
    path: "/ordenPedidoPendiente",
    name: "OCPendientes",
    component: OCPendientes,
    meta: {role: 'Seller'},

    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/vinculos",
    name: "Vinculos",
    component: Vinculos,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      } 
      next();
    }
  },
  {
    path: "/test-timming",
    name: "test-timming",
    component: testTimming,
  },
  {
    path: "/metrics/front",
    name: "metrics-front",
    component: FrontMetrics,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      } 
      next();
    }
  },
  {
    path: "/metrics/soap",
    name: "metrics-backend",
    component: SoapMetrics,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      } 
      next();
    }
  },
  {
    path: "/invoicing-manager",
    name: "InvoicingManager",
    component: InvoicingManager,
    meta: {role: 'Manager'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ["Manager", 'Submanager', 'IELManager', 'IELSubManager'])) {
        return next({ name: "SignIn" });
      }
      next();
    }
  },
  {
    path: "/director-channel-selection",
    name: "DirectorChannelSelection",
    component: DirectorChannelSelection,
    meta: {role: 'Director'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      // if (store.getters["auth/authenticatedDirector"]) {
      //   return next({
      //     name: "Home",
      //   });
      // }
      if (!hasAnyRole(store.getters["auth/roles"], ["Director", "IELDirector","IELExpressSales"])) {
        return next({ name: "SignIn" });
      }
      next();
    }
  },
      /*New shopping cart*/
      {
        path: "/shoppingcart-pr/crear/:doctype/:idq",
        name: "NewShoppingCartPR",
        component: NewShoppingCartPR,
        meta: {
          role: 'Customer',
          reload: true,
        },
        props: true,
        beforeEnter: (to, from, next) => {
          if (!store.getters["auth/authenticated"]) {
            return next({
              name: "SignIn",
            });
          }
          if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
            return next({
              name: "403",
            });
          }
          next();
        },
      },
      /*New shopping cart*/
  {
    path: "/presupuesto-estadistica",
    name: "QuotationStatistics",
    component: QuotationStatistics,
    meta: { role: 'Seller'},

    beforeEnter: async (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      else if (!hasAnyRole(store.getters["auth/roles"], ["Seller"])) {
        return next({ name: "403" });
      }
      next();
    },
  },
  {
    path: "/backorder-reel",
    name: "CreateReelOrder",
    component: CreateReelOrder,
    meta: { role: 'Customer'},

    beforeEnter: async (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      else if (!hasAnyRole(store.getters["auth/roles"], ["CustomerPR", "Customer", 'Seller'])) {
        return next({ name: "403" });
      }
      next();
    },
  },
  {
    path: "/backorder-reel-reminder",
    name: "CreateReelReminderOrder",
    component: CreateReelReminderOrder,
    meta: { role: 'Customer'},

    beforeEnter: async (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      else if (!hasAnyRole(store.getters["auth/roles"], ["CustomerPR", "Customer", 'Seller'])) {
        return next({ name: "403" });
      }
      next();
    },
  },
  {
    path: "/combo-order",
    name: "CreateKitOrder",
    component: CreateKitOrder,
    meta: { role: 'Customer' },

    beforeEnter: async (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      else if (!hasAnyRole(store.getters["auth/roles"], ["CustomerPR", "Customer", 'Seller'])) {
        return next({ name: "403" });
      }
      next();
    },
  },
  {
    path: "/invoicing-director",
    name: "InvoicingDirector",
    component: InvoicingDirector,
    meta: {role: 'Director'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if(!store.getters["auth/authenticatedDirector"]){
        return next({
          name: "DirectorChannelSelection",
        });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ["Director", "IELDirector", "IELCommercialPR", "IELCommercialDT", "Subdirector", 'RegionalManager', 'IELRegManager'])) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/structure-director",
    name: "StructureDirector",
    component: StructureHome,
    meta: {role: 'Director'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if(!store.getters["auth/authenticatedDirector"]){
        return next({
          name: "DirectorChannelSelection",
        });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['Director', 'IELDirector', 'IELCommercialPR', 'IELCommercialDT', 'Subdirector'])) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/structure-regional-manager",
    name: "StructureRegionalManager",
    component: StructureHome,
    meta: {role: 'Regional'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['Director', 'IELDirector', 'IELCommercialPR', 'IELCommercialDT', 'Subdirector', 'RegionalManager', 'IELRegManager'])) {
        return next({ name: "SignIn" });
      }
      next();
    },
  },
  {
    path: "/consultasDT",
    name: "ConsultasDT",    
    meta: {role: 'Seller'},
    component: ConsultasDT,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  //logistics-reports
  {
    path: "/logistics-reports",
    name: "logistics_reports",
    component: logistics_reports,
    meta: {role: 'IELLogistics'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
    
      if (!hasAnyRole(store.getters["auth/roles"], ["IELLogistics"])) {
        return next({ name: "SignIn" });
      }
      next();
    }
  },
  /*New shopping cart*/
  {
    path: "/shoppingcart-pr-bk/crear/:doctype/:idb",
    name: "NewShoppingCartBackPR",
    component: NewShoppingCartBackPR,
    meta: {
      role: 'Customer',
      reload: true,
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
        /*New shopping cart*/
  {
    path: "/shoppingcart-bk/crear/:doctype/:idb",
    name: "NewShoppingCartBack",
    component: NewShoppingCartBack,
    meta: {
      role: 'Customer',
      reload: true,
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
      }
      next();
    },
  },
  {
    path: "/request-for-quotation",
    name: "CreateRequestForQuotation",
    component: () => import('../views/request_for_quotation/CreateRequestForQuotation'),
    meta: { role: 'Customer' },
    
    beforeEnter: async (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      else if (!hasAnyRole(store.getters["auth/roles"], ["CustomerPR", "Customer", 'Seller'])) {
        return next({ name: "403" });
      }
      next();
    },
  },
  {
    path: "/create-notification",
    name: "Notifications",
    component: Notifications,
    meta: {role: 'NotificationAdmin'},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "SignIn",
        });
      }
      if (hasAnyRole(store.getters["auth/roles"], ["Guest"])) {
        return next({
          name: "403",
        });
       }
      next();
    }
  },
  {
    path: "/request-for-quotation/tracking",
    name: "QuotationTracking",
    component: () => import('../views/request_for_quotation/HistoryTracking'),
    meta: { role: 'Customer' },
    
    beforeEnter: async (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      else if (!hasAnyRole(store.getters["auth/roles"], ["CustomerPR", "Customer", 'Seller'])) {
        return next({ name: "403" });
      }
      next();
    },
  },
  {
    path: "/results-express-sales",
    name: "ResultsExpressSales",
    component: ResultsExpressSales,
    meta: { role: "ExpressSales", reload: true },
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['IELExpressSales'])) {
        return next({
          name: "403",
        });
      }
      next();
    }
  }, 
  {
    path: "/results-express-sales",
    name: "ResultsExpressSalesPR",
    component: ResultsExpressSales,
    meta: { role: "ExpressSales" , reload: true},
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['IELExpressSales'])) {
        return next({
          name: "403",
        });
      }
      next();
    }
  },
  {
    path: "/general-group-data-counters-director",
    name: "GeneralGroupDataCountersDirector",
    component: GeneralGroupDataCounters,
    meta: { role: "Director" },
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      if(!store.getters["auth/authenticatedDirector"]){
        return next({
          name: "DirectorChannelSelection",
        });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['Director', 'Subdirector', 'IELDirector', 'IELCommercialPR', 'IELCommercialDT'])) {
        return next({
          name: "403",
        });
      }
      next();
    }
  }, 
  {
    path: "/general-group-data-counters-regional",
    name: "GeneralGroupDataCountersRegional",
    component: GeneralGroupDataCounters,
    meta: { role: "Regional" },
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({ name: "SignIn" });
      }
      if (!hasAnyRole(store.getters["auth/roles"], ['Director', 'Subdirector', 'IELDirector', 'IELCommercialPR', 'IELCommercialDT', 'RegionalManager', 'IELRegManager'])) {
        return next({
          name: "403",
        });
      }
      next();
    }
  }, 
  {
    path: "/403",
    name: "403",
    component: Forbidden,
  },
];

const routeNotFound = [
{
  path: "/:pathMatch(.*)*",
  beforeEnter: (to, from, next) => {
    if (!store.getters["auth/authenticated"]) {
      return next({ name: "SignIn"});
      }
    next();
  },
}]; 

const  Allroutes = routes.concat(
  refundsRoutes,
  administratorRoutes,
  sellerRoutes,
  creditRoutes,
  directorRoutes,
  routeNotFound);//siempre poner routeNotFound al final
 
const router = new VueRouter({
  mode: "history",
  //mode: "history",
  base: process.env.BASE_URL,
  routes:Allroutes,
});

router.beforeEach((to, from, next) => {
  if(to.meta.redirectpermissions && hasAnyPermission(store.getters["auth/permissions"], to.meta.redirectpermissions)) {
    next({ name: "RequestsSeller" });
  }
  store.dispatch('cancellableRequest/abortRequests'); // Canceling pending http requests when view changes
  next();
})

export default router;
